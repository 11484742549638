import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Checkbox = props => (
    <div
        className={[props.className, styles.checkbox].join(' ')}
        onClick={() => props.onClick({ name: props.name, value: props.checked })}
    >
        <div
            className={[
                styles.box,
                props.checked === true ? styles.checked : '',
                props.checkClassName || ''
            ].join(' ')}
            tabIndex={0}
        >
            <div />
        </div>
        <div className={styles.label_block}>
            <label>{props.label && props.label}</label>
            <span className={styles.error}>{props.error && props.error}</span>
        </div>
    </div>
);

Checkbox.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    checkClassName: PropTypes.string,
    error: PropTypes.string
};

export default Checkbox;