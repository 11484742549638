import { useState, useEffect } from 'react';

const getOrientation = screenWidth => {
    if (screenWidth) return (screenWidth === window.innerWidth) ? 'portrait' : 'landscape';
    return (window.innerHeight > window.innerWidth) ? 'portrait' : 'landscape';
};

const useOrientationChange = screenWidth => {
    const [orientation, setOrientation] = useState(getOrientation());

    useEffect(() => {
        const handler = () => {
            setOrientation(getOrientation(screenWidth));
        };
        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return orientation;
};

export default useOrientationChange;