import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../UI/Button';
import { useTranslation } from 'react-i18next';

import translations from '../../../constants/translations';
import * as routes from '../../../constants/routes';
import { trackPageView, trackCustomEvent, GTM_CATEGORY_ONLINE_CHECKUP } from '../../../utils';

import ErrorCircle from '../../UI/ErrorCircle';
import PhotoPreview from '../../UI/PhotoPreview';

import * as styles from './styles.module.scss';

const TakePhoto = props => {
    const { t, i18n } = useTranslation();
    const [loader, setLoader] = React.useState({ start: false, progress: 0 });

    React.useEffect(() => {
        if (props.location.state && props.location.state.code) {
            startLoader();
        }
    }, [props.location.state]);

    React.useEffect(() => {
        loader.start && updateProgress();
    }, [loader]);

    const startLoader = () => {
        setLoader({ ...loader, start: true });
        props.setQuizLoader({
            show: true,
            message:  t(translations.takePhoto.photoProcessing)
        });
    };

    const stopLoader = () => {
        props.setQuizLoader({ show: false, progress: 0, message: '' });
        setLoader({ ...loader, start: false });
    };

    const updateProgress = () => {
        setTimeout(() => {
            if (loader.progress < 100) {
                setLoader({ ...loader, progress: loader.progress + 1 });
                props.setQuizLoader({ progress: loader.progress + 1 });
            } else {
                stopLoader();
            }
        }, 15);
    };

    const handleClick = action => {
        if (action === 'Continue without photo') {
            trackPageView({
                title: t(translations.gtmData.homePageStep3.title),
                url: t(translations.gtmData.homePageStep3.url)
            });
            trackCustomEvent(GTM_CATEGORY_ONLINE_CHECKUP, 'Take Photo Page', action);
        }
        props.onNextStep();
    };

    const renderButtons = buttons => (
        buttons.map((button, index) => {

            return (
                <Button
                    key={index}
                    text={button.title}
                    onClick={() => button.takePhoto ? props.history.push('/' + i18n.language + routes.CAMERA) : handleClick(button.title)}
                />
            );
        })
    );

    const renderResponseMesage = () => {
        return t(translations.takePhoto.response, { returnObjects: true }).map((response, index) => {
            if (response.code === props.location.state.code) {
                return (
                    <div key={index}>
                        <p
                            className={styles.title}
                            dangerouslySetInnerHTML={{ __html: response.title }}
                        />
                        {response.code === 200
                            ? <PhotoPreview image={props.location.state.mouth ? props.location.state.mouth : (t(translations.takePhoto.image))} />
                            : <ErrorCircle />}
                        <p className={styles.description}>{response.description}</p>
                        { renderButtons(response.options) }
                    </div>
                );
            }
        });
    };

    const renderTakePhoto = () => {
        return (
            <>
                <p
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: t(translations.takePhoto.title) }}
                />
                <p className={styles.description}>{t(translations.takePhoto.description)}</p>
                <PhotoPreview image={t(translations.takePhoto.image)} />
                { renderButtons(t(translations.takePhoto.options, { returnObjects: true })) }
            </>
        );
    };

    return (
        <div className={styles.take_photo}>
            {props.location.state && props.location.state.code ? renderResponseMesage() : renderTakePhoto()}
        </div>
    );
};

TakePhoto.propTypes = {
    onNextStep: PropTypes.func,
    setQuizLoader: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(TakePhoto);