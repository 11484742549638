const GtmHead = localGtmId => {
    window.dataLayer = [];
    window.dataLayer.push({
        'gtm.start': new Date().getTime(), event: 'gtm.js'
    });

    const firstScriptTag = document.getElementsByTagName('script')[0];
    const gtmScriptTag = document.createElement('script');

    gtmScriptTag.async = true;
    gtmScriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${localGtmId}&l=dataLayer`;

    return firstScriptTag.parentNode.insertBefore(gtmScriptTag, firstScriptTag);
};

export default GtmHead;