import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actionTypes/quiz';
import { trackPageView, trackCustomEvent, GTM_CATEGORY_ONLINE_CHECKUP } from '../../../utils';

import translations from '../../../constants/translations';
import Button from '../../UI/Button';
import quizStructure from '../../../mock/quizStructure';
import quizResultsStructure from '../../../mock/quizResultsStructure';
import { changeAnswers, remapQuestions, resetDisabledAnswers, getAnswer, generateResult, getResultType } from '../../../utils/quizHelper';
import * as styles from './styles.module.scss';

const Quiz = props => {
    const { t } = useTranslation();
    const [questions, setQuestions] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [loader, setLoader] = useState({ start: false, progress: 0 });

    useEffect(() => {
        !questions && setQuestions(quizStructure);
        loader.start && updateProgress();
    }, [questions, answers, loader]);

    useEffect(() => {
        if (loader.progress === 100) {
            trackPageView({
                title: t(translations.gtmData.homePageStep4.title),
                url: t(translations.gtmData.homePageStep4.url)
            });
        }
    }, [loader.progress]);

    const startLoader = () => {
        setLoader({ ...loader, start: true });
        props.setQuizLoader({
            show: true,
            message:  t(translations.quiz.loaderMessage)
        });
    };

    const stopLoader = () => {
        props.setQuizLoader({ show: false, progress: 0, message: '' });
        setLoader({ ...loader, start: false });
    };

    const updateProgress = () => {
        setTimeout(() => {
            if (loader.progress < 100) {
                setLoader({ ...loader, progress: loader.progress + 1 });
                props.setQuizLoader({ progress: loader.progress + 1 });
            } else {
                stopLoader();
                props.onNextStep();
            }
        }, 15);
    };

    const onButtonClick = (questionId, question, answer) => {
        trackCustomEvent(GTM_CATEGORY_ONLINE_CHECKUP, t(question).replace(/<\/?[^>]+(>|$)/g, ''), t(answer.name));

        const newAnswers = changeAnswers(questionId, answer, answers);
        const resetedAnswers = resetDisabledAnswers(questions, newAnswers);
        const tempQuestions = remapQuestions(questions, resetedAnswers);

        setAnswers([...resetedAnswers]);
        setQuestions([...tempQuestions]);

        if (answer && answer.submit) {
            props.setQuizResult({
                answers:  resetedAnswers,
                answersCombination: generateResult(resetedAnswers),
                type: getResultType(quizResultsStructure, generateResult(resetedAnswers))
            });

            startLoader();
        } else if (getAnswer(answer.id, resetedAnswers)) {
            props.onSubStep('current');
        }
    };

    const mapButtons = (questionId, question, buttons) => buttons.map(button => (
        <Button
            text={t(button.name)}
            key={button.id}
            onClick={() => onButtonClick(questionId, question, button)}
            isQuizButton={true}
            active={button.active}
            disabled={button.disabled}
        />
    ));

    const buttonMapper = buttonContent => buttonContent.map(item => (
        <div key={item.id}>
            <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: t(item.name) }}
            />
            <div>
                {mapButtons(item.id, item.name, item.answers)}
            </div>
        </div>
    ));

    return (
        <div className={styles.quiz}>
            {questions && buttonMapper(questions)}
        </div>
    );
};

Quiz.propTypes = {
    onNextStep: PropTypes.func,
    onSubStep: PropTypes.func,
    setQuizResult: PropTypes.func,
    setQuizLoader: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        setQuizResult: data => dispatch(actionTypes.setQuizResult(data))
    };
};

export default connect(null, mapDispatchToProps)(Quiz);