import * as actions from '../../../constants/actionTypes';

const setQuizResult = data => {
    return {
        type: actions.SET_QUIZ_RESULT,
        data
    };
};

const unsetQuizResult = () => {
    return {
        type: actions.UNSET_QUIZ_RESULT
    };
};

export {
    setQuizResult,
    unsetQuizResult
};
