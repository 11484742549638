import * as rules from '../constants/formValidationRules';
import { deepCloneSimpleObject } from './index';

// Check if the value is not empty string
const validateRequired = value => !(!value || ((typeof value === 'string') && value.trim() === ''));

// Check if the email is valid
const validateEmail = value =>
    // eslint-disable-next-line max-len
    !!(new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
        .test(value));

// Check if the field has all valid characters
const validateCharacters = value => !!(new RegExp('^[a-zA-Z-,]+(\\s{0,1}[a-zA-Z-, ]){0,256}$').test(value));

// Check if the field has all valid characters for address
const validateAddress = value => !!(new RegExp('^[a-zA-Z0-9\\s, \'-\\.\\/]{0,256}$').test(value));

// Check if the filed has all number characters
const validateNumbers = value => !!(new RegExp('^[0-9]*$').test(value));

// Check if the filed has all number characters
// eslint-disable-next-line max-len
const validateAbbreviations = value => !!(new RegExp('^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$').test(value));

// Check if the filed is phone number
// eslint-disable-next-line no-useless-escape
const validatePhoneNumber = value => !!(new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$').test(value));

export const validate = field => {
    let validateField = deepCloneSimpleObject(field);

    if (validateField.rules && validateField.rules.length > 0) {
        validateField.rules.map(rule => {
            switch (rule.type) {
                case rules.REQUIRED:
                    validateField.error = !validateRequired(validateField.value) ? rule.errorMessage : '';
                    validateField.isValid = !validateRequired(validateField.value) ? false : true;
                    break;
                case rules.EMAIL:
                    if (validateField.isValid) {
                        validateField.error = !validateEmail(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validateEmail(validateField.value) ? false : true;
                    }
                    break;
                case rules.INVALID_CHARACTERS:
                    if (validateField.isValid) {
                        validateField.error = !validateCharacters(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validateCharacters(validateField.value) ? false : true;
                    }
                    break;
                case rules.ADDRESS:
                    if (validateField.isValid) {
                        validateField.error = !validateAddress(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validateAddress(validateField.value) ? false : true;
                    }
                    break;
                case rules.ONLY_NUMBERS:
                    if (validateField.isValid) {
                        validateField.error = !validateNumbers(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validateNumbers(validateField.value) ? false : true;
                    }
                    break;
                case rules.PHONE_NUMBER:
                    if (validateField.isValid) {
                        validateField.error = !validatePhoneNumber(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validatePhoneNumber(validateField.value) ? false : true;
                    }
                    break;
                case rules.ABBREVIATIONS:
                    if (validateField.isValid) {
                        validateField.error = !validateAbbreviations(validateField.value) ? rule.errorMessage : '';
                        validateField.isValid = !validateAbbreviations(validateField.value) ? false : true;
                    }
                    break;
                default:
                    validateField.isValid = true;
                    break;
            }
        });
    }

    return validateField;
};

export const validateResponse = (field, responseError) => {
    let validateField = deepCloneSimpleObject(field);

    if (responseError) {
        validateField.rules.map(rule => {
            if (rule.type === responseError.Name) {
                switch (rule.type) {
                    case rules.RESPONSE_EMAIL:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_STATE:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_ZIP:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_ADDRESS:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_ZIP4:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_DUPED_ID:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_DUPED_ZIP4:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                    case rules.RESPONSE_LIMIT:
                        validateField.error = rule.errorMessage === responseError.Message ? rule.errorMessage : responseError.Message;
                        validateField.isValid = false;
                        break;
                }
            }
        });
    }

    return validateField;
};