export const REQUIRED = 'REQUIRED';
export const EMAIL = 'EMAIL';
export const INVALID_CHARACTERS = 'INVALID_CHARACTERS';
export const ABBREVIATIONS = 'ABBREVIATIONS';
export const ADDRESS = 'ADDRESS';
export const ONLY_NUMBERS = 'ONLY_NUMBERS';
export const PHONE_NUMBER = 'PHONE_NUMBER';

export const RESPONSE_EMAIL = 'Email';
export const RESPONSE_STATE = 'State';
export const RESPONSE_ZIP = 'ZIP';
export const RESPONSE_ADDRESS = 'Address';
export const RESPONSE_ZIP4 = 'Zip4';
export const RESPONSE_DUPED_ID = 'DupedId';
export const RESPONSE_DUPED_ZIP4 = 'DupedZip4';
export const RESPONSE_LIMIT = 'DailyLimit';