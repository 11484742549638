import translations from '../../../constants/translations';
import * as rules from '../../../constants/formValidationRules';
import * as api from '../../../constants/api';

const sampleFormStructure = {
    firstName: {
        name: 'firstName',
        label: translations.sampleForm.labels.firstName,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.INVALID_CHARACTERS,
                errorMessage: translations.sampleForm.errors.invalidCharacters
            }
        ],
        isValid: true,
        error: ''
    },
    lastName: {
        name: 'lastName',
        label: translations.sampleForm.labels.lastName,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.INVALID_CHARACTERS,
                errorMessage: translations.sampleForm.errors.invalidCharacters
            }
        ],
        isValid: true,
        error: ''
    },
    email: {
        name: 'email',
        label: translations.sampleForm.labels.email,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.EMAIL,
                errorMessage: translations.sampleForm.errors.email
            },
            {
                type: rules.RESPONSE_EMAIL,
                errorMessage: translations.sampleForm.errors.validationEmail
            }
        ],
        isValid: true,
        error: ''
    },
    address: {
        name: 'address',
        label: translations.sampleForm.labels.address,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.ADDRESS,
                errorMessage: translations.sampleForm.errors.address
            },
            {
                type: rules.RESPONSE_ADDRESS,
                errorMessage: translations.sampleForm.errors.validationAddress
            },
            {
                type: rules.RESPONSE_ZIP4,
                errorMessage: translations.sampleForm.errors.validationAddress
            }
        ],
        isValid: true,
        error: ''
    },
    city: {
        name: 'city',
        label: translations.sampleForm.labels.city,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.INVALID_CHARACTERS,
                errorMessage: translations.sampleForm.errors.invalidCharacters
            }
        ],
        isValid: true,
        error: ''
    },
    zipcode: {
        name: 'zipcode',
        label: translations.sampleForm.labels.zipcode,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.ONLY_NUMBERS,
                errorMessage: translations.sampleForm.errors.onlyNumbers
            },
            {
                type: rules.RESPONSE_ZIP,
                errorMessage: translations.sampleForm.errors.validationZip
            }
        ],
        isValid: true,
        error: ''
    },
    state: {
        name: 'state',
        label: translations.sampleForm.labels.state,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.required
            },
            {
                type: rules.RESPONSE_STATE,
                errorMessage: translations.sampleForm.errors.validationState
            }
        ],
        isValid: true,
        error: ''
    },
    subscribe: {
        name: 'subscribe',
        label: translations.sampleForm.labels.subscribe,
        elementType: 'input',
        elementConfig: {
            type: 'checkbox',
            placeholder: ''
        },
        value: false,
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.sampleForm.errors.requiredCheckbox
            }
        ],
        isValid: true,
        error: ''
    },
    submit: {
        name: 'submit',
        label: translations.sampleForm.labels.submit,
        elementType: 'button',
        elementConfig: {
            type: 'submit',
            placeholder: ''
        },
        value: '',
        isValid: true,
        rules: [
            {
                type: rules.RESPONSE_DUPED_ID,
                errorMessage: translations.sampleForm.errors.validartionDoupedId
            },
            {
                type: rules.RESPONSE_DUPED_ZIP4,
                errorMessage: translations.sampleForm.errors.validartionDoupedZip
            },
            {
                type: rules.RESPONSE_LIMIT,
                errorMessage: translations.sampleForm.errors.validartionLimit
            }
        ],
        error: ''
    }
};

const submitRoute = api.API_SAMPLE_USER_DATA;

export {
    sampleFormStructure,
    submitRoute
};