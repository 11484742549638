import * as resultTypes from '../constants/quizResultTypes';

const quizResults = [
    {
        type: resultTypes.PAINFREE,
        answersCombinations: ['c']
    },
    {
        type: resultTypes.LOW,
        answersCombinations: []
    },
    {
        type: resultTypes.MEDIUM,
        answersCombinations: ['a-a-a']
    },
    {
        type: resultTypes.HIGH,
        answersCombinations: ['b-a-a']
    },
    {
        type: resultTypes.OTHER,
        answersCombinations: ['a-a-b','a-b-a','a-b-b','a-c-b','a-c-a','b-a-b','b-b-a','b-b-b','b-c-a','b-c-b']
    }
];

export default quizResults;