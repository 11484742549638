import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import translations from '../../constants/translations';

import * as styles from './styles.module.scss';
import sensodyneLogo from '../../assets/svg/sensodyne-logo.svg';

import * as routes from '../../constants/routes';
import * as actions from '../../store/actions/app';

const Header = props => {
    const { t, i18n } = useTranslation();

    const handleHomepageLogoClick = () => {
        props.history.push(`/${i18n.language}${routes.ROOT}`);
        props.takeTest(false);
    };

    return (
        <div className={styles.header}>
            <a onClick={() => handleHomepageLogoClick()}>
                <img src={t(translations.headerLogo)}/>
            </a>
            <img src={sensodyneLogo}/>
        </div>
    );
};

Header.propTypes = {
    history: PropTypes.object,
    takeTest: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        takeTest: test => dispatch(actions.takeTest(test))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Header));