import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import getDeviceType from '../../components/hooks/useDeviceType';
import translations from '../../constants/translations';
import { connect } from 'react-redux';

import Prefooter from '../Prefooter';
import Terms from '../Terms';

import * as styles from './styles.module.scss';

const Footer = props => {
    const { t } = useTranslation();
    const isMobileDevice = getDeviceType();

    const renderFooter = () => {
        return (
            <div className={styles.footer}>
                {!props.terms && <Terms />}
                {(props.show && props.terms) && <Prefooter />}
                <ul>
                    <li>
                        <a
                            href={t(translations.home.termsAndConditionsLink)}
                            rel='noopener noreferrer'
                            target='_blank'
                        >
                            {t(translations.home.termsAndConditionsTitle)}
                        </a>
                    </li>
                    <li>
                        <a
                            href={t(translations.home.contactLink)}
                            target='_blank'
                            rel="noreferrer noopener"
                        >
                            {t(translations.home.contactTitle)}
                        </a>
                    </li>
                    <li>
                        <a
                            href={t(translations.home.privacyPolicyLink)}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            <span dangerouslySetInnerHTML={{ __html: t(translations.home.privacyPolicyTitle) }}></span>
                        </a>
                    </li>
                    <li>
                        <a
                            href={t(translations.home.cookiePolicyLink)}
                            target='_blank'
                            rel="noreferrer noopener"
                        >
                            <span dangerouslySetInnerHTML={{ __html: t(translations.home.cookiePolicyTitle) }}></span>
                        </a>
                    </li>
                </ul>
                {props.show && <div className={styles.footer_disclaimer}>{t(translations.home.disclaimerText)}</div>}
            </div>
        );
    };

    return (
        <>
            {isMobileDevice === true && props.hide !== true && renderFooter()}
        </>
    );
};

Footer.propTypes = {
    show: PropTypes.bool,
    hide: PropTypes.bool,
    terms: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        terms: state.appData.terms
    };
};

export default connect(mapStateToProps)(Footer);