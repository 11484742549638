import React from 'react';

import { useTranslation } from 'react-i18next';
import translations from '../../../constants/translations';

import styles from './styles.module.scss';

const PortraitInfo = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.portrait_info}>
            <div
                className={styles.landscape_text}
                dangerouslySetInnerHTML={{ __html: t(translations.home.portraitWarning) }}
            />
        </div>
    );
};

export default PortraitInfo;