import translations from '../../../constants/translations';
import * as rules from '../../../constants/formValidationRules';
import * as api from '../../../constants/api';

const couponFormStructure = {
    firstName: {
        name: 'firstName',
        label: translations.couponForm.labels.firstName,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.couponForm.errors.required
            },
            {
                type: rules.INVALID_CHARACTERS,
                errorMessage: translations.couponForm.errors.invalidCharacters
            }
        ],
        isValid: true,
        error: ''
    },
    lastName: {
        name: 'lastName',
        label: translations.couponForm.labels.lastName,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.couponForm.errors.required
            },
            {
                type: rules.INVALID_CHARACTERS,
                errorMessage: translations.couponForm.errors.invalidCharacters
            }
        ],
        isValid: true,
        error: ''
    },
    email: {
        name: 'email',
        label: translations.couponForm.labels.email,
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: ''
        },
        value: '',
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.couponForm.errors.required
            },
            {
                type: rules.EMAIL,
                errorMessage: translations.couponForm.errors.email
            }
        ],
        isValid: true,
        error: ''
    },
    subscribe: {
        name: 'subscribe',
        label: translations.couponForm.labels.subscribe,
        elementType: 'input',
        elementConfig: {
            type: 'checkbox',
            placeholder: ''
        },
        value: false,
        rules: [
            {
                type: rules.REQUIRED,
                errorMessage: translations.couponForm.errors.requiredCheckbox
            }
        ],
        isValid: true,
        error: ''
    },
    submit: {
        name: 'submit',
        label: translations.couponForm.labels.submit,
        elementType: 'button',
        elementConfig: {
            type: 'submit',
            placeholder: ''
        },
        value: '',
        rules: [],
        isValid: true,
        error: ''
    }
};

const submitRoute = api.API_COUPON_USER_DATA;

export {
    couponFormStructure,
    submitRoute
};