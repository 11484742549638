import React from 'react';
import styles from './styles.module.scss';

const ErrorCircle = () => (
    <div
        className={styles.errorCircle}
    >
        <p className={styles.errorCircle_circle}>X</p>
    </div>
);

export default ErrorCircle;