import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translations from '../../../constants/translations';
import { withTranslation } from 'react-i18next';

import Button from '../../UI/Button';
import Input from '../../UI/Input';
import Checkbox from '../../UI/Checkbox';
import Select from '../../UI/Select';

import { deepCloneSimpleObject } from '../../../utils';
import { validate, validateResponse } from '../../../utils/formValidation';
import * as responseType from '../../../constants/responseTypes';

import quizStructure from '../../../mock/quizStructure';
import styles from './styles.module.scss';

class SampleForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: null,
            apiRoute: ''
        };

        this.fieldRefs = [];
    }

    componentWillMount = () => {
        if (!this.state.form) {
            const formStructure = require(`../../../mock/formStructures/${this.props.i18n.language}/sample`);
            this.setState({ apiRoute: formStructure.submitRoute });
            const formTemp = deepCloneSimpleObject(formStructure.sampleFormStructure);
            const formKeys = Object.keys(formTemp);

            formKeys.forEach(key => {
                this.fieldRefs[key] = React.createRef();
            });

            this.setState({ form: formTemp });
        }
    };

    validateForm = validateForm => {
        const formKeys = Object.keys(validateForm);

        const isFormValid = formKeys.map(key => {
            validateForm[key] = validate(validateForm[key]);
            return validateForm[key].isValid;
        });

        this.setState({ form: validateForm });
        return !isFormValid.includes(false);
    };

    onHandleChange = field => {
        let formTemp = deepCloneSimpleObject(this.state.form);
        formTemp[field.name].value = formTemp[field.name].elementConfig.type === 'checkbox' ? !field.value : field.value;
        formTemp[field.name] = validate(formTemp[field.name]);

        this.setState({ form: formTemp });
    };

    scrollToInvalidFeild = () => {
        const tempForm = deepCloneSimpleObject(this.state.form);
        const formKeys = Object.keys(tempForm);
        const invalidFields = [];

        formKeys.forEach(key => {
            const validatedField = validate(tempForm[key]);

            if (validatedField.isValid === false) {
                invalidFields.push({ name: key, field: validatedField });
            }
        });

        if (invalidFields.length > 0) {
            const fieldName = invalidFields[0].name;
            const elementPositions = this.fieldRefs[fieldName].current.getBoundingClientRect();
            this.props.scrollToField(elementPositions.top - 100);
        }
    };

    handleSubmit = () => {
        const formTemp = deepCloneSimpleObject(this.state.form);
        const isFormValid = this.validateForm(formTemp);
        const answers = this.props.quizResult.answers.map(answer => {
            const question = quizStructure.find(question => question.id === answer.question);
            const answ = question.answers.find(qAnswer => qAnswer.id === answer.answer);
            return {
                question: this.props.t(question.name).replace(/<\/?[^>]+(>|$)/g, ''),
                answer: this.props.t(answ.name)
            };
        });

        const formData = {
            first_name: formTemp.firstName.value,
            last_name: formTemp.lastName.value,
            email: formTemp.email.value,
            address: formTemp.address.value,
            city: formTemp.city.value,
            postcode: formTemp.zipcode.value,
            state: formTemp.state.value,
            phone_number: formTemp.phone ? formTemp.phone.value : 'NA',
            comms_opt_in: true,
            optedFor: 'S',
            result: this.props.quizResult.type,
            attributes: answers
        };

        if (isFormValid) {
            this.props.onSubmit(this.state.apiRoute, formData).then(response => {
                if (response.Type === responseType.PAYLOAD) {
                    this.errorHandler(response.Fields);
                } else if (response.Type === responseType.SUBMISSION) {
                    this.props.onNextStep();
                } else {
                    const err = { Name: response.Type, Errors: [response.Message] };
                    this.errorHandler([ err ]);
                    this.scrollToInvalidFeild();
                }
            });
        } else {
            this.scrollToInvalidFeild();
        }
    };

    errorHandler = errors => {
        let formTemp = deepCloneSimpleObject(this.state.form);
        errors.map(error => {
            const err = { Name: error.Name, Message: error.Errors[0] };
            const formKeys = Object.keys(formTemp);

            formKeys.map(key => {
                formTemp[key] = validateResponse(formTemp[key], err);
                return formTemp[key].isValid;
            });

            this.setState({ form: formTemp });
        });
    };

    renderForm = () => {
        const formKeys = Object.keys(this.state.form);

        return formKeys.map((key, index) => {
            switch (this.state.form[key].elementConfig.type) {
                case 'checkbox':
                    return (
                        <div key={index} ref={this.fieldRefs[key]}>
                            <Checkbox
                                name={this.state.form[key].name}
                                label={this.props.t(this.state.form[key].label)}
                                checked={this.state.form[key].value}
                                onClick={this.onHandleChange}
                                error={this.props.t(this.state.form[key].error)}
                            />
                        </div>
                    );
                case 'submit':
                    return (
                        <div key={index} ref={this.fieldRefs[key]}>
                            <Button
                                text={this.props.t(this.state.form[key].label)}
                                onClick={this.handleSubmit}
                                containerStyle={styles.submit}
                                error={this.props.t(this.state.form[key].error)}
                            />
                        </div>
                    );
                case 'select':
                    return (
                        <div key={index} ref={this.fieldRefs[key]}>
                            <Select
                                text={this.props.t(this.state.form[key].label)}
                                name={this.state.form[key].name}
                                options={this.state.form[key].options}
                                onInput={this.onHandleChange}
                                value={this.state.form[key].value}
                                error={this.props.t(this.state.form[key].error)}
                            />
                        </div>
                    );
                default:
                    return (
                        <div key={index} ref={this.fieldRefs[key]}>
                            <Input
                                name={this.state.form[key].name}
                                text={this.props.t(this.state.form[key].label)}
                                onChange={this.onHandleChange}
                                value={this.state.form[key].value}
                                error={this.props.t(this.state.form[key].error)}
                            />
                        </div>
                    );
            }
        });
    };

    render() {
        return (
            <div className={styles.sample_form}>
                <p className={styles.info_text}>{this.props.t(translations.sampleForm.paragraph)}</p>
                {this.state.form && this.renderForm()}
            </div>
        );
    }
}

SampleForm.propTypes = {
    onSubmit: PropTypes.func,
    scrollToField: PropTypes.func,
    quizResult: PropTypes.object,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    onNextStep: PropTypes.func
};

export default withTranslation()(SampleForm);