import * as actions from '../../../constants/actionTypes';

const loadingStart = () => {
    return {
        type: actions.LOADING_START
    };
};

const loadingSuccess = () => {
    return {
        type: actions.LOADING_SUCCESS
    };
};

const loadingFail = error => {
    return {
        type: actions.LOADING_FAIL,
        error
    };
};

const uploadImageSuccess = data => {
    return {
        type: actions.UPLOAD_IMAGE_SUCCESS,
        data
    };
};

const sampleAvailabilitySuccess = data => {
    return {
        type: actions.SAMPLE_AVAILABILITY_SUCCESS,
        data
    };
};

const takeTestSuccess = data => {
    return {
        type: actions.TAKE_TEST_SUCCESS,
        data
    };
};

const serviceSuccess = data => {
    return {
        type: actions.SERVICE_SUCCESS,
        data
    };
};

const setTermsSuccess = () => {
    return {
        type: actions.SET_TERMS_SUCCESS
    };
};

export {
    loadingStart,
    loadingSuccess,
    loadingFail,
    sampleAvailabilitySuccess,
    takeTestSuccess,
    uploadImageSuccess,
    setTermsSuccess,
    serviceSuccess
};