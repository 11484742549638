import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Arrow from '../../UI/Arrow';

const AccordionItem = props => {
    const classes = [styles.accordion_item];

    if (props.item.active) {
        classes.push(styles.active);
    }

    const handleClick = () => {
        props.onClick(props.item.id);
    };

    return (
        <div className={classes.join(' ')}>
            <div className={styles.accordion_item_title} onClick={handleClick}>
                <span>{props.item.title || 'Tooth structure'}</span>
                <Arrow active={props.item.active} />
            </div>
            <div className={styles.accordion_item_inner}>
                <div className={styles.accordion_item_content}>
                    <p className={styles.symptomTitle} suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: props.item.content }}></p>
                </div>
            </div>
        </div>
    );
};

AccordionItem.propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func
};

export default AccordionItem;