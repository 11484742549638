import { getLocal } from '../utils';

const handleGtmIds = environmentConfig => {
    const gtmUS = environmentConfig ? environmentConfig.GTMID_US : process.env.REACT_APP_GTMID_US;
    const gtmDE = environmentConfig ? environmentConfig.GTMID_DE : process.env.REACT_APP_GTMID_DE;
    const gtmIN = environmentConfig ? environmentConfig.GTMID_IN : process.env.REACT_APP_GTMID_IN;

    let gtmId;
    const local = getLocal() && getLocal().substr(1) || 'en-US';

    if (local === 'en-US') gtmId = gtmUS;
    if (local === 'de-DE') gtmId = gtmDE;
    if (local === 'en-IN') gtmId = gtmIN;

    return gtmId;
};

export {
    handleGtmIds
};