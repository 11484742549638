import Start from '../components/Screens/Start';
import TakePhoto from '../components/Screens/TakePhoto';
import Quiz from '../components/Screens/Quiz';
import ThankYou from '../components/Screens/ThankYou';
import Results from '../components/Screens/Results';

const swipeContent = [
    {
        local: 'en-US',
        screens: [
            {
                id: 1,
                Content: Start,
                disableScroll: false
            },
            {
                id: 2,
                Content: Quiz,
                disableScroll: true
            },
            {
                id: 3,
                Content: Results,
                disableScroll: true
            },
            {
                id: 4,
                Content: ThankYou,
                disableScroll: true
            }
        ]
    },
    {
        local: 'en-IN',
        screens: [
            {
                id: 1,
                Content: Start,
                disableScroll: false
            },
            {
                id: 2,
                Content: TakePhoto,
                disableScroll: true
            },
            {
                id: 3,
                Content: Quiz,
                disableScroll: true
            },
            {
                id: 4,
                Content: Results,
                disableScroll: true
            },
            {
                id: 5,
                Content: ThankYou,
                disableScroll: true
            }
        ]
    },
    {
        local: 'de-DE',
        screens: [
            {
                id: 1,
                Content: Start,
                disableScroll: false
            },
            {
                id: 2,
                Content: TakePhoto,
                disableScroll: true
            },
            {
                id: 3,
                Content: Quiz,
                disableScroll: true
            },
            {
                id: 4,
                Content: Results,
                disableScroll: true
            }
        ]
    }
];

export default swipeContent;