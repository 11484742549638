import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { trackPageView, getEnvironmentConfig } from '../utils';
import { handleGtmIds } from '../utils/gtmIdsHelper';
import GtmHead from '../Scripts/GtmHead';

import Layout from '../components/Layout';
import Home from '../pages/Home';
import StartPage from '../pages/Startpage';
import Camera from '../pages/Camera';
import CameraBlocked from '../pages/CameraBlocked';

import * as routes from '../constants/routes';

class App extends Component {
    environmentConfig = getEnvironmentConfig();

    componentDidMount(){
        document.head.appendChild(GtmHead(handleGtmIds(this.environmentConfig)));
        if (window && window.dataLayer && window.dataLayer.push && routes.ROOT) {
            trackPageView({
                title: 'Initial page load',
                url: '/'
            });
        }
    }

    render() {
        return (
            <Suspense fallback={null}>
                <Layout>
                    <Switch>
                        <Route path={'/:lang' + routes.STARTPAGE} component={StartPage} />
                        <Route path={'/:lang' + routes.CAMERA} component={Camera} />
                        <Route path={'/:lang' + routes.CAMERA_BLOCKED} component={CameraBlocked} />
                        <Route path={'/:lang' + routes.ROOT} exact component={Home} />
                        <Redirect to="en-US" />
                    </Switch>
                </Layout>
            </Suspense>
        );
    }
}

export default withRouter(App);
