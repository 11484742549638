import React from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actionTypes/app';

import styles from './styles.module.scss';
import translations from '../../constants/translations';
import Button from '../UI/Button';

const Terms = props => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({ 'consentMgrStatus': 'yes' });
        }
        props.setTerms();
    };

    return (
        <div className={styles.terms}>
            <div className={styles.terms_inner}>
                <p dangerouslySetInnerHTML={{ __html: t(translations.home.termsContent) }} />
                <Button
                    text={t(translations.home.termsButton)}
                    onClick={handleClick}
                    isQuizButton={true}
                    containerStyle={styles.button}
                />
            </div>
        </div>
    );
};

Terms.propTypes = {
    setTerms: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        setTerms: () => dispatch(actionTypes.setTermsSuccess())
    };
};

export default connect(null, mapDispatchToProps)(Terms);