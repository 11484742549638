import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextContainer from './TextContainer';
import Accordion from '../../Accordion';

import translations from '../../../constants/translations';

import styles from './styles.module.scss';

const ThankYou = props => {
    const { t } = useTranslation();

    const renderContent = () => {
        const content = t(translations.thanks[props.userService], { returnObjects: true });
        return Array.isArray(content) ? content : t(translations.thanks.sample, { returnObjects: true });
    };

    return (
        <div className={styles.thank_you_screen}>
            <TextContainer content={renderContent()} />
            {t(translations.thanks.accordion)
                && <Accordion
                    onAccordionClick={props.onAccordionClick}
                    content={t(translations.accordion, { returnObjects: true })}
                />
            }
            {t(translations.thanks.disclaimer) && <p className={styles.disclaimer}>{t(translations.thanks.disclaimer)}</p>}
        </div>
    );
};

ThankYou.propTypes = {
    onAccordionClick: PropTypes.func,
    userService: PropTypes.string
};

const mapStateToProps = state => {
    return {
        userService: state.appData.userService
    };
};

export default connect(mapStateToProps,null)(ThankYou);
