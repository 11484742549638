import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import { trackPageView, trackCustomEvent, GTM_CATEGORY_ONLINE_CHECKUP } from '../../../utils';

import translations from '../../../constants/translations';

import * as styles from './styles.module.scss';

const Start = props => {
    const { t } = useTranslation();

    const handleOnClick = () => {
        trackPageView({
            title: t(translations.gtmData.homePageStep2.title),
            url: t(translations.gtmData.homePageStep2.url)
        });
        trackCustomEvent(GTM_CATEGORY_ONLINE_CHECKUP, 'Start The Online Check Up', 'QuickCheckup - Take the Test');
        props.onNextStep();
    };

    return (
        <div className={styles.start}>
            <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: t(translations.start.title) }}
            />
            <p
                className={[styles.subtitle, styles.title].join(' ')}
                dangerouslySetInnerHTML={{ __html: t(translations.start.subtitle) }}
            />
            <img src={(t(translations.start.image))} />
            <Button
                text={(t(translations.start.buttonText))}
                onClick={handleOnClick}
                margin= "-20px auto 20px"
            />
            <span
                className={styles.disclaimer}
                dangerouslySetInnerHTML={{ __html: t(translations.start.disclaimer) }}
            />
        </div>
    );
};

Start.propTypes = {
    onNextStep: PropTypes.func,
    onSubStep: PropTypes.func
};

export default Start;