import React from 'react';
import PropTypes from 'prop-types';

import style from './style.module.scss';

const InputSelect = props => {

    const renderOptions = () => (
        props.options.map((option, index) => (
            <option
                key={index}
                value={option.name}>{option.name}
            </option>
        ))
    );

    return (
        <div className={[style.inputSelect, props.containerStyle].join(' ')}>
            <label>{props.text}</label>
            <select
                value={props.value}
                onChange={e => props.onInput(e.target)}
                name={props.name}
            >
                <option value='' disabled hidden>{props.value}</option>
                {renderOptions()}
            </select>
            {props.error && <span className={style.error} dangerouslySetInnerHTML={{ __html: props.error }} />}
        </div>
    );
};

InputSelect.propTypes = {
    text: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    onInput: PropTypes.func,
    value: PropTypes.any,
    containerStyle: PropTypes.string,
    error: PropTypes.string
};
export default InputSelect;