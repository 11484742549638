import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import translations from '../../constants/translations';

const Prefooter = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.prefooter}>
            <div className={styles.prefooter_inner}>{t(translations.home.prefooterText)}</div>
        </div>
    );
};

export default Prefooter;