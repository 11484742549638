const getEllipseSize = innerWidth => {
    const width = Math.min(200, innerWidth / 3);
    return {
        width,
        height: Math.min(200, width / 1.5)
    };
};

const getScaledVideoRatio = (video, innerWidth, innerHeight) => {
    const { videoWidth, videoHeight } = video;

    const scaleW = innerWidth / videoWidth;
    const scaleH = (innerHeight - 120) / videoHeight;

    const scale = Math.max(scaleW, scaleH);

    const width = videoWidth * scale;
    const height = videoHeight * scale;

    return {
        height,
        width,
        offsetY: (height - (innerHeight - 120)) / -2,
        offsetX: (width - innerWidth) / -2
    };
};

const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new window.Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new window.File([u8arr], filename, { type: mime });
};

export {
    getEllipseSize,
    getScaledVideoRatio,
    dataURLtoFile
};
