import translations from '../constants/translations';

const quizStructure = [
    {
        id: 1,
        name: translations.quiz.questions.first.title,
        relatedAnswers: [],
        answers: [
            {
                id: 1,
                name: translations.quiz.questions.first.answers.a,
                active: false,
                disabled: false,
                value: 'a'
            },
            {
                id: 2,
                name: translations.quiz.questions.first.answers.b,
                active: false,
                disabled: false,
                value: 'b'
            },
            {
                id: 3,
                name: translations.quiz.questions.first.answers.c,
                active: false,
                disabled: false,
                submit: true,
                value: 'c'
            }
        ]
    },
    {
        id: 2,
        name: translations.quiz.questions.second.title,
        relatedAnswers: [1, 2, 3],
        answers: [
            {
                id: 4,
                name: translations.quiz.questions.second.answers.a,
                active: false,
                disabled: true,
                value: 'a'
            },
            {
                id: 5,
                name: translations.quiz.questions.second.answers.b,
                active: false,
                disabled: true,
                value: 'b'
            },
            {
                id: 6,
                name: translations.quiz.questions.second.answers.c,
                active: false,
                disabled: true,
                value: 'c'
            }
        ]
    },
    {
        id: 3,
        name: translations.quiz.questions.third.title,
        relatedAnswers: [4, 5, 6],
        answers: [
            {
                id: 7,
                name: translations.quiz.questions.third.answers.a,
                active: false,
                disabled: true,
                submit: true,
                value: 'a'
            },
            {
                id: 8,
                name: translations.quiz.questions.third.answers.b,
                active: false,
                disabled: true,
                submit: true,
                value: 'b'
            }
        ]
    }
];

export default quizStructure;