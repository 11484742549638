import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as routes from '../../constants/routes';
import * as actions from '../../store/actions/app';

import Header from '../Header';
import Footer from '../Footer';

import DesktopInfo from '../Modals/DesktopInfo';
import PortraitInfo from '../Modals/PortraitInfo';
import getOrientation from '../hooks/useOrientationChange';
import getDeviceType from '../hooks/useDeviceType';
import * as orientationType from '../../constants/orientationTypes';
import * as styles from './styles.module.scss';

const Layout = props => {
    const [route, setRoute] = useState(props.location.pathname);
    const orientation = getOrientation(window.innerWidth);
    const isMobile = getDeviceType();
    const { i18n } = useTranslation();
    const wrapperStyles = [
        styles.main_wrapper,
        props.takeTest && styles.fadeOut,
        route === '/' + i18n.language + routes.STARTPAGE && styles.fadeIn
    ];

    useEffect(() => {
        setRoute(props.location.pathname);
        if (props.location.pathname && props.location.pathname.length > 5) {
            const local = props.location.pathname.substring(1, 6);
            if (local !== i18n.language && i18n.options.supportedLngs.indexOf(local) === -1) {
                props.history.push('/' + i18n.language + routes.ROOT);
            }
        }
    }, [props.location]);

    useEffect(() => {
        if (props.takeTest) {
            setTimeout(() => {
                props.history.push('/' + i18n.language + routes.STARTPAGE);
            }, 300);
        }
    }, [props.takeTest]);

    const renderContent = () => {
        if (!isMobile) {
            return (
                <DesktopInfo />
            );
        } else {
            return (
                <>
                    { props.children }
                    { orientation === orientationType.LANDSCAPE && <PortraitInfo /> }
                </>
            );
        }
    };

    return (
        <div className={wrapperStyles.join(' ')}>
            {route !== '/' + i18n.language + routes.ROOT && route !== '/' + i18n.language && route !== '/' + i18n.language + routes.CAMERA && <Header />}
            { renderContent() }
            <Footer show={route === '/' + i18n.language + routes.ROOT || route === '/' + i18n.language} hide={route === '/' + i18n.language + routes.CAMERA}/>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
    history: PropTypes.object,
    takeTest: PropTypes.bool,
    setTakeTest: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        setTakeTest: test => dispatch(actions.takeTest(test))
    };
};

const mapStateToProps = state => {
    return {
        takeTest: state.appData.takeTest
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));