import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { trackPageView, trackCustomEvent, GTM_CATEGORY_ONLINE_CHECKUP } from '../../utils';

import translations from '../../constants/translations';
import withBackgroundVideo from '../../components/hoc/withBackgroundVideo';
import Button from '../../components/UI/Button';
import getDeviceType from '../../components/hooks/useDeviceType';

import * as actions from '../../store/actions/app';

import sensodyneLogo from '../../assets/svg/sensodyne-logo.svg';
import * as styles from './styles.module.scss';

const VIDEO_SCALE_W_PER_H = 1080 / 730;

const Home = props => {
    const { t } = useTranslation();
    const [logoTop, setLogoTop] = useState(0);
    const [hexagonHeight, setHexagonHeight] = useState(0);
    const [buttonTop, setButtonTop] = useState(0);
    const isMobile = getDeviceType();

    React.useEffect(() => {
        setComponentsPosition();
    }, [props.scale]);

    React.useEffect(() => {
        props.takeTest(false);
        trackPageView({
            title: t(translations.gtmData.homePageStep0.title),
            url: t(translations.gtmData.homePageStep0.url)
        });
    }, []);

    const handleClick = () => {
        trackCustomEvent(GTM_CATEGORY_ONLINE_CHECKUP, 'Start The Online Check Up', 'Take the Test');
        props.takeTest(true);
    };

    const setComponentsPosition = () => {
        const baseHeight = window.screen.width / VIDEO_SCALE_W_PER_H;
        let hexHeight = 0.599 * baseHeight * props.scale;
        let logoImageTop = (window.screen.height / 2) - hexHeight;
        const screenRatio = window.screen.width / window.screen.height;
        let btnTop = logoImageTop + (hexHeight * (1 + screenRatio)) - 43 * 2.4;
        const isIphoneX = (isMobile && window.screen.width === 375 && window.screen.height === 812);

        if (window.screen.width === 320) {
            hexHeight = 0.675 * baseHeight * props.scale;
            logoImageTop = (window.screen.height / 2) - hexHeight;
            btnTop = logoImageTop + (hexHeight * 1.5) - 55 * 2.4;
        } else if (isIphoneX) {
            hexHeight = 0.7 * baseHeight * props.scale;
            logoImageTop = (window.screen.height / 2) - hexHeight;
            btnTop = logoImageTop + (hexHeight * 1.5) - 90 * 2.4;
        } else if (window.screen.width > 360 && window.screen.width < 750 && !isIphoneX) {
            hexHeight = screenRatio * baseHeight * props.scale;
            logoImageTop = (window.screen.height / 2) - hexHeight;
            btnTop = logoImageTop + (hexHeight * (1 + screenRatio)) - 63 * 2.2;
        } else if (window.screen.width > 750) {
            hexHeight = 0.485 * baseHeight * props.scale;
            logoImageTop = (window.screen.height / 2) - hexHeight;
            btnTop = logoImageTop + (hexHeight * 1.5) - 10 * 2.4;
        }

        setLogoTop(logoImageTop);
        setHexagonHeight(hexHeight);
        setButtonTop(btnTop);
    };

    return (
        <main>
            <div style={{ margin: '0 auto' }}>
                <div className={styles.homepageLogo} style={{ top: (logoTop + ((hexagonHeight - (hexagonHeight * .723)) / 2)) / 16 + 'rem' }}>
                    <img src={process.env.PUBLIC_URL + t(translations.home.desktopLogo)} />
                    <img src={sensodyneLogo} className={styles.sensodyneLogo} />
                </div>
                <div className={styles.buttonContainer} style={{ top: buttonTop / 16 + 'rem' }}>
                    <Button
                        text={props.startPageVisited ? t(translations.home.buttonRetakeText) : t(translations.home.buttonText)}
                        onClick={handleClick}
                        isQuizButton={false}
                        containerStyle={styles.button}
                    />
                </div>
            </div>
        </main>
    );
};

Home.propTypes = {
    history: PropTypes.object,
    scale: PropTypes.number,
    takeTest: PropTypes.func,
    location: PropTypes.object,
    startPageVisited: PropTypes.bool
};

const mapDispatchToProps = dispatch => {
    return {
        takeTest: test => dispatch(actions.takeTest(test))
    };
};

const mapStateToProps = state => {
    return {
        startPageVisited: state.appData.startPageVisited
    };
};

export default withBackgroundVideo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)), true);
