import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from './Item';

const Accordion = props => {
    const [list, setList] = useState(props.content);

    useEffect(() => {
        setList(props.content);
    }, [props.content]);

    const handleClick = id => {
        const mapListItems = list.map(listItem => {
            if (listItem.id === id) {
                return { ...listItem, active: !listItem.active };
            }
            return { ...listItem };
        });
        setList(mapListItems);
        setTimeout(() => {
            props.onAccordionClick();
        }, 350 );
    };

    const renderItem = () => {
        return list.map((item, index) =>
            <AccordionItem key={index} item={item} onClick={id => handleClick(id)}/>
        );
    };

    return (
        <div className={props.className}>
            { renderItem(list) }
        </div>
    );
};

Accordion.propTypes = {
    onAccordionClick: PropTypes.func,
    content: PropTypes.array,
    className: PropTypes.string
};

export default Accordion;