import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Button = props => {
    return (
        <>
            {props.error && <span className={styles.error} dangerouslySetInnerHTML={{ __html: props.error }} />}
            <div
                onClick={props.onClick}
                style={{ margin: props.margin }}
                className={[
                    styles.button,
                    props.containerStyle,
                    props.isQuizButton ? styles.button_quiz : styles.default_button,
                    props.active && styles.active,
                    props.disabled && styles.disabled
                ].join(' ')}
            >
                <span>{props.text}</span>
            </div>
        </>
    );
};

Button.propTypes = {
    text: PropTypes.string,
    containerStyle: PropTypes.string,
    isQuizButton: PropTypes.bool,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    margin: PropTypes.string
};

export default Button;