export default {
    home: {
        desktopLogo: 'home.desktopLogo',
        portraitWarning: 'home.portraitWarning',
        desktopInfo: 'home.desktopInfo',
        termsAndConditionsTitle: 'home.termsAndConditionsTitle',
        termsAndConditionsLink: 'home.termsAndConditionsLink',
        contactTitle: 'home.contactTitle',
        contactLink: 'home.contactLink',
        privacyPolicyTitle: 'home.privacyPolicyTitle',
        privacyPolicyLink: 'home.privacyPolicyLink',
        cookiePolicyTitle: 'home.cookiePolicyTitle',
        cookiePolicyLink: 'home.cookiePolicyLink',
        disclaimerText: 'home.disclaimerText',
        buttonText: 'home.buttonText',
        buttonRetakeText: 'home.buttonRetakeText',
        prefooterText: 'home.prefooterText',
        termsContent: 'home.termsContent',
        termsButton: 'home.termsButton'
    },
    displaySample: 'displaySample',
    displayCoupon: 'displayCoupon',
    displayResetButton: 'displayResetButton',
    pageTitle: 'pageTitle',
    headerLogo: 'headerLogo',
    accordion: 'accordion',
    start: {
        title: 'screens.start.title',
        subtitle: 'screens.start.subtitle',
        image: 'screens.start.image',
        buttonText: 'screens.start.buttonText',
        disclaimer: 'screens.start.disclaimer'
    },
    takePhoto: {
        title: 'screens.takePhoto.title',
        description: 'screens.takePhoto.description',
        image: 'screens.takePhoto.image',
        photoProcessing: 'screens.takePhoto.photoProcessing',
        options: 'screens.takePhoto.options',
        response: 'screens.takePhoto.response'
    },
    quiz: {
        loaderMessage: 'screens.quiz.loaderMessage',
        questions: {
            first: {
                title: 'screens.quiz.questions.first.title',
                answers: {
                    a: 'screens.quiz.questions.first.answers.a',
                    b: 'screens.quiz.questions.first.answers.b',
                    c: 'screens.quiz.questions.first.answers.c'
                }
            },
            second: {
                title: 'screens.quiz.questions.second.title',
                answers: {
                    a: 'screens.quiz.questions.second.answers.a',
                    b: 'screens.quiz.questions.second.answers.b',
                    c: 'screens.quiz.questions.second.answers.c'
                }
            },
            third: {
                title: 'screens.quiz.questions.third.title',
                answers: {
                    a: 'screens.quiz.questions.third.answers.a',
                    b: 'screens.quiz.questions.third.answers.b'
                }
            }
        }
    },
    thanks: {
        accordion: 'screens.thanks.accordion',
        sample: 'screens.thanks.sample',
        coupon: 'screens.thanks.coupon',
        default: 'screens.thanks.default',
        disclaimer: 'screens.thanks.disclaimer'
    },
    results: {
        title: 'screens.results.title',
        sampleButton: 'screens.results.sampleButton',
        sampleSorryMessage: 'screens.results.sampleSorryMessage',
        couponButton: 'screens.results.couponButton',
        couponSorryMessage: 'screens.results.couponSorryMessage',
        accordion: 'screens.results.accordion',
        disclaimer: 'screens.results.disclaimer',
        barcode: {
            title: 'screens.results.barcode.title',
            image: 'screens.results.barcode.image',
            pdfLink: 'screens.results.barcode.pdfLink'
        },
        high: {
            title: 'screens.results.high.title',
            text: 'screens.results.high.text',
            description: 'screens.results.high.description',
            image: 'screens.results.high.image',
            learnMore: 'screens.results.high.learnMore'
        },
        medium: {
            title: 'screens.results.medium.title',
            text: 'screens.results.medium.text',
            description: 'screens.results.medium.description',
            image: 'screens.results.medium.image',
            learnMore: 'screens.results.medium.learnMore'
        },
        low: {
            title: 'screens.results.low.title',
            text: 'screens.results.low.text',
            description: 'screens.results.low.description',
            image: 'screens.results.low.image',
            learnMore: 'screens.results.low.learnMore'
        },
        painfree: {
            title: 'screens.results.painfree.title',
            text: 'screens.results.painfree.text',
            description: 'screens.results.painfree.description',
            image: 'screens.results.painfree.image',
            learnMore: 'screens.results.painfree.learnMore'
        },
        other: {
            title: 'screens.results.other.title',
            text: 'screens.results.other.text',
            description: 'screens.results.other.description',
            image: 'screens.results.other.image',
            learnMore: 'screens.results.other.learnMore'
        }
    },
    disableCamera: 'disableCamera',
    sampleForm: {
        paragraph: 'sampleForm.paragraph',
        labels: {
            firstName: 'sampleForm.labels.firstName',
            lastName: 'sampleForm.labels.lastName',
            email: 'sampleForm.labels.email',
            address: 'sampleForm.labels.address',
            city: 'sampleForm.labels.city',
            zipcode: 'sampleForm.labels.zipcode',
            phone: 'sampleForm.labels.phone',
            state: 'sampleForm.labels.state',
            subscribe: 'sampleForm.labels.subscribe',
            submit: 'sampleForm.labels.submit'
        },
        errors: {
            required: 'sampleForm.errors.required',
            requiredCheckbox: 'sampleForm.errors.requiredCheckbox',
            email: 'sampleForm.errors.email',
            onlyNumbers: 'sampleForm.errors.onlyNumbers',
            phoneNumber: 'sampleForm.errors.phoneNumber',
            invalidCharacters: 'sampleForm.errors.invalidCharacters',
            abbreviations: 'sampleForm.errors.abbreviations',
            address: 'sampleForm.errors.address',
            validationEmail: 'sampleForm.errors.validationEmail',
            validationZip: 'sampleForm.errors.validationZip',
            validationState: 'sampleForm.errors.validationState',
            validationAddress: 'sampleForm.errors.validationAddress',
            validartionDoupedId: 'sampleForm.errors.validartionDoupedId',
            validartionDoupedZip: 'sampleForm.errors.validartionDoupedZip',
            validartionLimit: 'sampleForm.errors.validartionLimit'
        }
    },
    couponForm: {
        paragraph: 'couponForm.paragraph',
        labels: {
            firstName: 'couponForm.labels.firstName',
            lastName: 'couponForm.labels.lastName',
            email: 'couponForm.labels.email',
            subscribe: 'couponForm.labels.subscribe',
            submit: 'couponForm.labels.submit'
        },
        errors: {
            required: 'couponForm.errors.required',
            requiredCheckbox: 'couponForm.errors.requiredCheckbox',
            email: 'couponForm.errors.email',
            invalidCharacters: 'couponForm.errors.invalidCharacters'
        }
    },
    gtmData: {
        initialPageLoad: {
            title: 'gtmData.initiaPagelLoad.title',
            url: 'gtmData.initalPageLoad.url'
        },
        homePageStep0: {
            title: 'gtmData.homePage.step0.title',
            url: 'gtmData.homePage.step0.url'
        },
        homePageStep1: {
            title: 'gtmData.homePage.step1.title',
            url: 'gtmData.homePage.step1.url'
        },
        homePageStep2: {
            title: 'gtmData.homePage.step2.title',
            url: 'gtmData.homePage.step2.url'
        },
        homePageStep3: {
            title: 'gtmData.homePage.step3.title',
            url: 'gtmData.homePage.step3.url'
        },
        homePageStep4: {
            title: 'gtmData.homePage.step4.title',
            url: 'gtmData.homePage.step4.url'
        },
        homePageStep5: {
            title: 'gtmData.homePage.step5.title',
            url: 'gtmData.homePage.step5.url'
        },
        cameraStep2: {
            title: 'gtmData.cameraPage.step2.title',
            url: 'gtmData.cameraPage.step2.url'
        }
    }
};
