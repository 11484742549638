import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '../../UI/Button';

import * as styles from './styles.module.scss';
import translations from '../../../constants/translations';
import * as localizations from '../../../constants/localizations';
import * as actions from '../../../store/actions/app';

import CouponForm from '../../Forms/CouponForm';
import SampleForm from '../../Forms/SampleForm';
import Accordion from '../../Accordion';
import SorryMessage from './SorryMessage';

const Results = props => {
    const { t, i18n } = useTranslation();
    const [showSampleForm, setShowSampleForm] = useState(false);
    const [showCouponForm, setShowCouponForm] = useState(false);
    const [showSampleSorryMessage, setShowSampleSorryMessage] = useState(false);
    const [showCouponSorryMessage, setShowCouponSorryMessage] = useState(false);
    const toggleFormRef = useRef();

    const scrollToButton = () => {
        const buttonPositions = toggleFormRef.current.getBoundingClientRect();
        const scrollPosition = buttonPositions.top - buttonPositions.height;
        props.onSubStep(scrollPosition);
    };

    const onToggleForm = toggleSampleForm => {
        props.toggleForm();
        if (toggleSampleForm) {
            setShowCouponForm(false);
            props.sampleAvailable ? setShowSampleForm(!showSampleForm) : setShowSampleSorryMessage(!showSampleSorryMessage);
        } else {
            setShowSampleForm(false);
            setShowSampleSorryMessage(false);
            setShowCouponForm(!showCouponForm);
        }

        !showSampleForm && !showCouponForm && scrollToButton();

        setTimeout(() => {
            props.onAccordionClick();
        }, 0);
    };

    const handleSampleDataSubmit = async (route, data) => {
        const service = 'sample';
        return await props.uploadSampleData(route, data, service).then(response => {
            return response;
        });
    };

    const handleCouponDataSubmit = async (route, data) => {
        const service = 'default';
        return await props.uploadCouponData(route, data, service)
            .then(res => {
                // TODO - handle thankyouscreen after iFrame
                // props.onNextStep();
                setTimeout(() => {
                    props.onAccordionClick();
                }, 0);
                return ({ status: 200, res });
            }).catch(err => {
                setShowCouponSorryMessage(true);
                setShowCouponForm(false);
                return ({ status: 404, err });
            });
    };

    const renderFormButton = () => (
        <>
            {t(translations.displaySample)
                && <Button
                    text={t(translations.results.sampleButton)}
                    onClick={() => onToggleForm(true)}
                    disabled={showCouponForm}
                    active={showSampleForm}
                    margin="0px auto 15px"
                />
            }
            {t(translations.displayCoupon)
                && <Button
                    text={t(translations.results.couponButton)}
                    onClick={() => onToggleForm(false)}
                    disabled={showSampleForm}
                    active={showCouponForm}
                    margin="0px auto 15px"
                />
            }
            {t(translations.displayResetButton) && <Button text={t(translations.home.buttonRetakeText)} onClick={() => window.location.reload(false)} />}
        </>
    );

    const resolveResultType = () => {
        return (
            <>
                <div ref={toggleFormRef} className={styles.container_button}>
                    {renderFormButton()}
                </div>
                {showSampleForm &&
                    <SampleForm
                        onSubmit={handleSampleDataSubmit}
                        quizResult={props.quizResult}
                        onNextStep={() => props.onNextStep()}
                        scrollToField={props.onSubStep}
                    />
                }
                {showSampleSorryMessage && <SorryMessage text={t(translations.results.sampleSorryMessage)} />}
                {showCouponForm && <CouponForm onSubmit={handleCouponDataSubmit} quizResult={props.quizResult} scrollToField={props.onSubStep} />}
                {showCouponSorryMessage && <SorryMessage text={t(translations.results.couponSorryMessage)} />}
            </>
        );
    };

    const resolveResultContent = () => {
        const learnMore = t(translations.results[props.quizResult.type].learnMore, { returnObjects: true });
        return learnMore && learnMore.map((learn, index) => (
            <div key={index}>
                <p
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: learn.title }}
                />
                {learn.faqAccordion && <Accordion onAccordionClick={props.onAccordionClick} content={t(translations.accordion, { returnObjects: true })}/>}
                {learn.accordion
                    && <Accordion
                        className={styles.accordion}
                        onAccordionClick={props.onAccordionClick}
                        content={t(translations.results.accordion, { returnObjects: true })}
                    />
                }
                <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: learn.text }}
                />
            </div>
        ));
    };

    const renderBarcodeSection = () => (
        <>
            <p
                className={styles.barcode_title}
                dangerouslySetInnerHTML={{ __html: t(translations.results.barcode.title) }}
            />
            <a href={t(translations.results.barcode.pdfLink)} target="_blank" rel="noreferrer" className={styles.barcode_image}>
                <img src={t(translations.results.barcode.image)} alt="" />
            </a>
        </>
    );

    return (
        <div className={[styles.container, styles[i18n.language]].join(' ')}>
            <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: t(translations.results.title) }}
                onClick={props.onNextStep}
            />
            {i18n.language !== localizations.enUS && <p
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: props.quizResult.type && t(translations.results[props.quizResult.type].description) }}
            />}
            <div className={styles.imageContainer}>
                <img src={props.quizResult.type && t(translations.results[props.quizResult.type].image)} alt="" />
            </div>

            {i18n.language === localizations.deDE && renderBarcodeSection()}

            <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: props.quizResult.type && t(translations.results[props.quizResult.type].title) }}
            />
            {resolveResultType()}
            <p
                className={styles.disclaimer}
                dangerouslySetInnerHTML={{ __html: t(translations.results.disclaimer) }}
            />
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: props.quizResult.type && t(translations.results[props.quizResult.type].text) }}
            />
            {props.quizResult.type && resolveResultContent()}
        </div>
    );
};

Results.propTypes = {
    history: PropTypes.object,
    translation: PropTypes.object,
    onNextStep: PropTypes.func,
    onSubStep: PropTypes.func,
    quizResult: PropTypes.object,
    onAccordionClick: PropTypes.func,
    toggleForm: PropTypes.func,
    uploadSampleData: PropTypes.func,
    uploadCouponData: PropTypes.func,
    sampleAvailable: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        quizResult: state.quizData.result,
        sampleAvailable: state.appData.sampleAvailable
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uploadSampleData: (route, data, service) => dispatch(actions.uploadSampleData(route, data, service)),
        uploadCouponData: (route, data, service) => dispatch(actions.uploadCouponData(route, data, service))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Results));