import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Tile = props => {
    return (
        <div className={styles.wrapper}>
            {props.dangerouslySetInnerHTML ? (
                <div suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: props.dangerouslySetInnerHTML }} className={styles.container} />
            ) : (
                <div suppressHydrationWarning={true} className={styles.container}>
                    {props.children}
                </div>
            )}
        </div>
    );
};

Tile.propTypes = {
    children: PropTypes.element,
    dangerouslySetInnerHTML: PropTypes.string
};

export default Tile;