import React from 'react';
import PropTypes from 'prop-types';

const SorryMessage = props => {
    return (
        <div dangerouslySetInnerHTML={{ __html: props.text }}>
        </div>
    );
};

SorryMessage.propTypes = {
    text: PropTypes.string
};

export default SorryMessage;