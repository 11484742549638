import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import range from 'lodash/range';
import Dot from './Dot';

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.delayTime = 100;
    }

    render() {
        return (
            <div className={[styles.progress_bar, this.props.style || ''].join(' ')}>
                {range(this.props.length).map((v, i) => (
                    <Dot
                        key={i}
                        active={i === this.props.active}
                        delay={(i + 1) * this.delayTime}
                    />
                ))}
            </div>
        );
    }
}

ProgressBar.propTypes = {
    length: PropTypes.number,
    active: PropTypes.number,
    style: PropTypes.object
};

export default ProgressBar;
