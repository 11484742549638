import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const LoadingScreen = props => {
    const isLoaded = (props.progress && props.progress === 100);

    return (
        <div className={styles.loading_screen}>
            <div className={styles.container}>
                <p
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: !isLoaded
                        ? props.loadingMessage
                        : (props.loadedMessage || props.loadingMessage) }}
                />
                <div className={styles.circle_container}>
                    <div className={[
                        styles.circle,
                        !isLoaded ? styles.containerAnimation : ''
                    ].join(' ')}>
                        <p className={styles.percentage}>{props.progress && props.progress}</p>
                    </div>
                    <div className={styles.circle_shadow} />
                </div>
            </div>
        </div>
    );
};

LoadingScreen.propTypes = {
    progress: PropTypes.number,
    loadingMessage: PropTypes.string.isRequired,
    loadedMessage: PropTypes.string
};

export default LoadingScreen;