import axios from 'axios';
import { getEnvironmentConfig, getLocal } from '../utils';

const environmentConfig = getEnvironmentConfig();

const hostUrl = environmentConfig
    ? environmentConfig.hostUrl
    : process.env.REACT_APP_HOSTURL;

let axiosInstance = null;

const initializeAxios = () => {
    axiosInstance = axios.create({
        baseURL: `${hostUrl}/api/v1`
    });

    axiosInstance.interceptors.request.use(
        request => {
            const locale = getLocal() && getLocal().substr(1) || 'en-US';
            request.headers['X-Locale'] = locale;
            return request;
        },
        error => Promise.reject(error)
    );
};

export {
    axiosInstance,
    initializeAxios
};