import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-xhr-backend';

import translation_en from '../translations/en-US/translation.json';
import translation_de from '../translations/de-DE/translation.json';
import translation_in from '../translations/en-IN/translation.json';

const resources = {
    'en-US': { translation: translation_en },
    'de-DE': { translation: translation_de },
    'en-IN': { translation: translation_in }
};

const translationPath = process.env.REACT_APP_ENVIRONMENT === 'production' ?
    '/locales/{{lng}}/{{ns}}.json' :
    null;

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        resources,
        preload: ['en-US'],
        load: 'currentOnly',
        supportedLngs: ['en-US', 'de-DE', 'en-IN'],
        saveMissing: true,
        saveMissingTo: 'all',
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: translationPath,
            addPath: translationPath
        },
        react: {
            wait: true,
            useSuspense: false
        },
        fallbackLng: [{ default: ['en-US'] }],
        detection: {
            order: ['path', 'localStorage', 'navigator'],
            lookupFromPathIndex: 0
        },
        debug: false
    }, () => {
        console.log('i18n initialized:', i18n.language);
    });

i18n.on('languageChanged', function (lng) {
    if (i18n.options.debug) {
        console.log('Language changed: ', { lng });
    }
});

i18n.on('failedLoading', function(lng, ns, msg) {
    if (i18n.options.debug) {
        console.log('Failed loading: ', { lng, ns, msg });
    }
});

i18n.on('missingKey', function(lngs, namespace, key, res) {
    if (i18n.options.debug) {
        console.log('Missing Key: ', { lngs, namespace, key, res });
    }
});

export default i18n;