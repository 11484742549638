import './utils/polyfills';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import freezeState from 'redux-freeze-state';
import { initializeAxios } from './axios';
import './i18n';

import './styles.module.scss';
import App from './entry';
import * as serviceWorker from './serviceWorker';

import appReducer from './store/reducers/app';
import quizReducer from './store/reducers/quiz';

const isDevelopment = process.env.NODE_ENV !== 'production';

const composeEnhancers = isDevelopment
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer = combineReducers({
    appData: appReducer,
    quizData: quizReducer
});

const middleware = [
    thunk,
    isDevelopment && logger
].filter(Boolean);

const store = createStore(
    isDevelopment ? freezeState(rootReducer) : rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);

initializeAxios(store.dispatch);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
