import React from 'react';
import { doesSupportWebm } from '../../../utils';
import getDeviceType from '../../hooks/useDeviceType';

import styles from './styles.module.scss';
import introLoopVideoMp4 from '../../../assets/videos/intro-loop.mp4';
import introLoopVideoWebm from '../../../assets/videos/intro-loop.webm';
import homeScreenVideoMp4 from '../../../assets/videos/bg-home-screens-opt.mp4';
import homeScreenVideoWebm from '../../../assets/videos/bg-home-screens-opt.webm';

const withBackgroundVideo = (Component, isHomePage, isModal) => {

    return () => {
        const isMobileDevice = getDeviceType();
        const [classes, setClasses] = React.useState([]);
        const [key, setKey] = React.useState(new Date().getTime());
        const [scale, setScale] = React.useState(0);
        const videoRef = React.useRef();

        React.useEffect(() => {
            setKey(new Date().getTime());
            setClasses([
                isHomePage === true ? '' : styles.startpage,
                isMobileDevice === true ? '' : styles.desktop,
                styles.video_container,
                isModal === true ? styles.modal : ''
            ].join(' '));
            calculateScale();
        }, [isMobileDevice]);

        const resolveBackgroundVideo = () => {
            const supportWebm = doesSupportWebm();
            if (isHomePage && isMobileDevice) {
                if (supportWebm) {
                    return introLoopVideoWebm;
                }
                return introLoopVideoMp4;
            }
            if (supportWebm) {
                return homeScreenVideoWebm;
            }
            return homeScreenVideoMp4;
        };

        const calculateScale = () => {
            if ((videoRef || {}).current) {

                let scaleHorizontal = window.innerWidth / videoRef.current.clientWidth;
                let scaleVertical = window.innerHeight / videoRef.current.clientHeight;

                if (scaleHorizontal < 1 || scaleHorizontal > 2) {
                    scaleHorizontal = Math.pow(scaleHorizontal, -1);
                }

                if (scaleVertical < 1 || scaleVertical > 2) {
                    scaleVertical = Math.pow(scaleVertical, -1);
                }

                const diff = Math.abs(scaleVertical - scaleHorizontal);
                setScale(1.2 + diff);
            }
        };

        return (
            <div className={styles.container}>
                <div className={classes}>
                    <video
                        ref={videoRef}
                        key={key}
                        autoPlay
                        loop
                        muted
                        playsInline
                        suppressHydrationWarning={true}
                        src={resolveBackgroundVideo()}
                        style={{ transform: `scale(${scale}` }}
                    ></video>
                </div>
                <Component scale={scale}/>
            </div>
        );
    };
};

export default withBackgroundVideo;