import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { trackPageView, trackCustomEvent, GTM_CATEGORY_ONLINE_CHECKUP } from '../../utils';
import translations from '../../constants/translations';

import Camera from '../../components/Camera';

import * as styles from './styles.module.scss';
import * as routes from '../../constants/routes';
import * as actions from '../../store/actions/app';

import useWindowSize from '../../components/hooks/useWindowSize';
import { dataURLtoFile } from '../../utils/cameraHelper';

const CameraPage = props => {
    const [facingMode, setFacingMode] = useState('user');
    const [flash, setFlash] = useState(false);
    const [captureImage, setCaptureImage] = useState(false);
    const dimensions = useWindowSize();
    const { i18n, t } = useTranslation();

    React.useEffect(() => {
        trackPageView({
            title: t(translations.gtmData.cameraStep2.title),
            url: t(translations.gtmData.cameraStep2.url)
        });
        trackCustomEvent(GTM_CATEGORY_ONLINE_CHECKUP, 'Take Photo Page', 'Take Photo');
    }, []);

    const handleUploadImage = async data => {
        const imageData = await data;
        const formatedImage = new FormData();
        formatedImage.set('photo', dataURLtoFile(imageData.image, 'snapshot'));
        props.uploadImage(formatedImage, imageData.mouth)
            .then(response => {
                props.history.push({ pathname: '/' + i18n.language + routes.STARTPAGE, state: { index: 1, code: response.status, mouth: imageData.mouth } });
            })
            .catch(err => {
                props.history.push({ pathname: '/' + i18n.language + routes.STARTPAGE, state: { index: 1, code: err.error.code } });
            });
    };

    return (
        <main className={styles.camera_page}>
            <div className={styles.camera}>
                <Camera
                    captureImage={captureImage}
                    onClick={handleUploadImage}
                    innerWidth={dimensions.width}
                    innerHeight={dimensions.height}
                    useFlash={flash}
                    facingMode={facingMode}
                    locale={i18n.language}
                />
            </div>
            <div className={styles.navigation}>
                <div className={styles.camera_options}>
                    <div
                        className={styles.back_button}
                        onClick={() => props.history.push({ pathname: '/' + i18n.language + routes.STARTPAGE, state: { index: 1 } })}
                    />
                </div>
                <div className={styles.camera_options}>
                    <div
                        className={styles.camera_button}
                        onClick={() => setCaptureImage(true)}
                    />
                </div>
                <div className={styles.camera_options}>
                    <div
                        className={[styles.camera_options_flash, flash ? styles.active : ''].join(' ')}
                        onClick={() => setFlash(!flash)}
                    />
                    <div
                        className={styles.camera_options_facing_mode}
                        onClick={() => setFacingMode(facingMode === 'user' ? 'environment' : 'user')}
                    />
                </div>
            </div>
        </main>
    );
};

CameraPage.propTypes = {
    history: PropTypes.object,
    uploadImage: PropTypes.func,
    location: PropTypes.object
};

const mapDispatchToProps = dispatch => {
    return {
        uploadImage: (image, mouth) => dispatch(actions.uploadImage(image, mouth))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(CameraPage));