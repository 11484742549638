import React from 'react';
import PropTypes from 'prop-types';
import 'hammerjs';
import { DIRECTIONS, SCROLL_DIRECTIONS } from '../../../constants/swiperOptions';
import styles from './styles.module.scss';

const EASING = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
const ITEM_SPACING = 60;

class ScrollContainer extends React.Component {
    constructor(props) {
        super(props);

        const { innerWidth, innerHeight } = window;

        this.props.setSlides(React.Children.count(this.props.children));
        this.slideRefs = React.Children.map(this.props.children, () => React.createRef());
        this.slideSizes = [];
        this.rootRef = React.createRef();
        this.containerRef = React.createRef();

        this.offset = 0;
        this.top = 0;

        this.state = {
            initialRender: true,
            key: new Date().getTime(),
            innerWidth,
            innerHeight
        };
    }

    componentDidMount() {
        this.timeout = setTimeout(() => {
            this.setState({
                initialRender: false
            });
            this.measureSlides();
            this.offset = this.getOffset(true);
            this.centerSlides();
        }, 250);

        this.containerRef.current.addEventListener('transitionend', this.animationEnd);
        window.addEventListener('resize', this.onResize);
        this.initTouchEvents();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentSlide !== this.props.currentSlide) {
            this.timeout = setTimeout(() => {
                this.updateSlideHeights();
                this.centerSlides();
            }, 5);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onResize = () => {
        const { innerWidth, innerHeight } = window;

        if (!this.props.isFormOpen) {
            if (this.containerRef && this.containerRef.current) {
                this.containerRef.current.style.transform = '';
            }

            this.slideRefs.forEach(ref => {
                if (ref && ref.current) {
                    ref.current.style.transition = '';
                    ref.current.style.transform = '';
                }
            });

            window.requestAnimationFrame(() => {
                this.measureSlides();
                this.offset = this.getOffset(true);
                this.centerSlides();
            });
        }

        this.setState({
            innerWidth,
            innerHeight
        });
    };

    hasActiveAnimation = () => !!this.resolveAnimation;

    animationEnd = () => {
        this.containerRef.current.style.transition = '';

        if (this.resolveAnimation) {
            this.resolveAnimation();
            this.resolveAnimation = null;
        }
    };

    cancelScrollAnimation = cb => {
        if (this.hasActiveAnimation()) {
            this.animationEnd();
            return window.requestAnimationFrame(cb);
        }

        cb();
    };

    measureSlides = () => {
        this.slideSizes = this.slideRefs.map(slide =>
            slide && slide.current && slide.current.getBoundingClientRect()
        );
    };

    updateSlideHeights = () => {
        let topChange = 0;
        let height;
        let top;
        let bottom;

        this.slideSizes = this.slideSizes.map((size, index) => {
            const slide = this.slideRefs[index] && this.slideRefs[index].current;

            if (slide) {
                height = slide.getBoundingClientRect().height;
                top = size.top + topChange;
                topChange += height - size.height;
                bottom = size.bottom + topChange;
            }

            return {
                ...size,
                top,
                bottom,
                height
            };
        });
    };

    getOffset = forced => {
        if (!this.slideSizes[forced ? 0 : this.props.currentSlide]) {
            return;
        }

        const { height, top } = this.slideSizes[forced ? 0 : this.props.currentSlide];
        const center = Math.max((this.state.innerHeight - height) / 2, 60);
        const offset = forced ? center : this.offset || center;
        const topOffset = offset - top;
        const bottomOffset = this.getSlideBottom();
        const fitOnScreen = height + offset > this.state.innerHeight;

        if (fitOnScreen && bottomOffset > this.top && !forced) {
            return bottomOffset;
        }

        return topOffset;
    };

    centerSlides = () => {
        this.top = this.getOffset();
        this.top && this.animateSlides(this.top);
    };

    animateSlides = (target, duration = '.3') => {
        this.containerRef.current.style.transition = `transform ${duration}s ${EASING}`;
        this.containerRef.current.style.transform = `translate3d(0, ${target}px, 0)`;

        return new Promise(resolve => {
            this.resolveAnimation = () => resolve(target);
        });
    };

    initTouchEvents = () => {
        const hammer = new window.Hammer.Manager(this.rootRef.current);

        hammer.add(new window.Hammer.Pan({
            direction: window.Hammer.DIRECTION_VERTICAL,
            threshold: 0
        }));

        hammer.on('panmove', this.handlePanMove);
        hammer.on('panend', this.handlePanEnd);
    };

    handlePanMove = ({ deltaY }) => {
        if (this.props.disabled && this.slideSizes[this.props.currentSlide].height + this.offset <= this.state.innerHeight) {
            return;
        }

        this.cancelScrollAnimation(() => {
            this.containerRef.current.style.transform = `translate3d(0, ${this.top + deltaY}px, 0)`;
        });
    };

    getSlideBottom = () => -(this.slideSizes[this.props.currentSlide].bottom - this.state.innerHeight) - this.offset;

    computeScrollTop = ({ animatedDeltaY, direction, disablePositioning }) => {
        let scrollTop = this.top + animatedDeltaY;
        let isFreeScroll = disablePositioning;
        const { top, bottom, height } = this.slideSizes[this.props.currentSlide];
        const screenBottom = bottom - (Math.abs(scrollTop) + this.state.innerHeight);
        const screenTop = Math.abs(scrollTop) - top;
        const slideBottom = this.getSlideBottom();
        const oneScreenItem = height + this.offset <= this.state.innerHeight;

        if (!oneScreenItem && !disablePositioning) {
            const isBelowTopWithOffset = screenTop <= this.offset;
            // jump to top if distance is less than offset
            if (isBelowTopWithOffset && screenTop > 0 && direction === DIRECTIONS.UP) {
                scrollTop = this.offset - top;
            }

            // jump to bottom if distance is less than window height / 3
            const isAboveBottomWithOffset = screenBottom <= (this.state.innerHeight / 3);

            if (isAboveBottomWithOffset && screenBottom > 0 && direction === DIRECTIONS.DOWN) {
                scrollTop = slideBottom;
            }

            // important to keep this calculation below the jump animations
            // prevent overscrolling the active tile. Animate first till the end.
            const aboveBottom = bottom * -1 <= scrollTop - this.state.innerHeight;
            const belowTop = this.offset - top >= scrollTop;

            isFreeScroll = aboveBottom && belowTop;

            const shouldStickToTop = this.top !== (this.offset - top) && direction === -1 && !isFreeScroll;

            if (shouldStickToTop) {
                return {
                    scrollTop: this.offset - top,
                    isFreeScroll: true
                };
            }

            const shouldStickToBottom = this.top !== slideBottom && direction === 1 && !isFreeScroll;
            if (shouldStickToBottom) {
                return {
                    scrollTop: slideBottom,
                    isFreeScroll: true
                };
            }
        }

        return { scrollTop, isFreeScroll };
    };

    freeScrollTile = ({ deltaY, velocityY, direction, disablePositioning }) => {
        const animatedDeltaY = deltaY + ((deltaY * velocityY * 2) * -direction);
        const { scrollTop, isFreeScroll } = this.computeScrollTop({ animatedDeltaY, direction, disablePositioning });

        if (isFreeScroll) {
            this.animateSlides(scrollTop, .5).then(newTop => {
                this.top = newTop;
            });
        }

        return isFreeScroll;
    };

    canScrollToNextSlide = direction => {
        switch (direction) {
            case DIRECTIONS.UP:
                return this.props.scrollDirection !== SCROLL_DIRECTIONS.ONLY_DOWN && !!this.props.currentSlide;
            case DIRECTIONS.DOWN:
                return this.props.currentSlide < this.props.slides - 1;
            default:
                return false;
        }
    };

    centerCurrentSlide = deltaY => {
        const { top, bottom, height } = this.slideSizes[this.props.currentSlide];
        const oneScreenItem = height + this.offset <= this.state.innerHeight;
        let scrollTop = this.offset - top;

        if (!oneScreenItem && -bottom > (this.top + deltaY) - this.state.innerHeight) {
            scrollTop = this.getSlideBottom();
        }

        this.animateSlides(scrollTop, .5).then(newTop => {
            this.top = newTop;
        });
    };

    scrollTo = (deltaY, { disablePositioning = true, direction = DIRECTIONS.DOWN, velocity = 1 } = {}) => {
        return this.freeScrollTile({ deltaY, velocityY: velocity, direction, disablePositioning });
    };

    handlePanEnd = ({ deltaY, velocityY }) => {
        const direction = deltaY > 0 ? DIRECTIONS.UP : DIRECTIONS.DOWN;

        if (this.freeScrollTile({ deltaY, velocityY, direction })) {
            return;
        }

        if (this.props.disabled) {
            return this.centerCurrentSlide(deltaY);
        }

        if (Math.abs(deltaY) > 50 && this.canScrollToNextSlide(direction)) {
            this.props.setCurrentSlide(this.props.currentSlide + direction);
        } else {
            this.centerSlides();
        }
    };

    getSlideStyle = i => {
        const isInactive = (i !== this.props.currentSlide && !this.state.initialRender);

        return {
            opacity: (isInactive || this.state.initialRender) ? 0 : 1,
            transform: isInactive ? `translate3d(0, ${-ITEM_SPACING}px, 0)` : '',
            transition: `opacity .3s ${EASING}, transform .3s ${EASING}`
        };
    };

    render() {
        return (
            <div className={styles.root} ref={this.rootRef}>
                <div className={styles.container} ref={this.containerRef}>
                    {React.Children.map(this.props.children, (child, i) => {
                        return (
                            <div
                                key={`slide-${i}`}
                                ref={this.slideRefs[i]}
                                className={styles.slide_wrapper}
                                style={this.getSlideStyle(i)}
                            >
                                {child}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

ScrollContainer.propTypes = {
    children: PropTypes.array,
    innerHeight: PropTypes.number,
    innerWidth: PropTypes.number,
    scrollDirection: PropTypes.oneOf(Object.values(SCROLL_DIRECTIONS)),
    disabled: PropTypes.bool,
    setSlides: PropTypes.func,
    setCurrentSlide: PropTypes.func,
    currentSlide: PropTypes.number,
    slides: PropTypes.number,
    isFormOpen: PropTypes.bool
};

export default ScrollContainer;