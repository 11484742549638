import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Button from '../../components/UI/Button';
import withBackgroundVideo from '../../components/hoc/withBackgroundVideo';
import translations from '../../constants/translations';
import * as routes from '../../constants/routes';

import * as styles from './styles.module.scss';

const DisableCameraPage = props => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (!content) {
            setContent(t(translations.disableCamera, { returnObjects: true }));
        }
    });

    return (
        <div className={styles.disable_camera}>
            <div className={styles.container}>
                <p className={styles.title}>{content && content.title}</p>
                <p className={styles.text}>{content && content.paragraph}</p>
                <Button
                    text={content && content.buttonText}
                    isQuizButton={false}
                    onClick={() => props.history.push({ pathname: '/' + i18n.language + routes.STARTPAGE, state: { index: 1 } })}
                    containerStyle={styles.button}
                />
            </div>
        </div>
    );
};

DisableCameraPage.propTypes = {
    history: PropTypes.object
};

export default withBackgroundVideo(withRouter(DisableCameraPage));