import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import translations from '../../constants/translations';
import { trackPageView } from '../../utils';
import * as actions from '../../store/actions/app';

import withBackgroundVideo from '../../components/hoc/withBackgroundVideo';
import Swipe from '../../components/Swipe';

const StartPage = props => {
    const { t } = useTranslation();

    React.useEffect(() => {
        trackPageView({
            title: t(translations.gtmData.homePageStep1.title),
            url: t(translations.gtmData.homePageStep1.url)
        });
        t(translations.displaySample) && props.sampleAvailability();
    }, []);

    return <Swipe />;
};

StartPage.propTypes = {
    location: PropTypes.object,
    sampleAvailability: PropTypes.func
};

const mapDispatchToProps = dispatch => {
    return {
        sampleAvailability: () => dispatch(actions.sampleAvailability())
    };
};

export default withBackgroundVideo(connect(null, mapDispatchToProps)(StartPage));