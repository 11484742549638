import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getEllipseSize, getScaledVideoRatio } from '../../utils/cameraHelper';

import * as routes from '../../constants/routes';

import * as styles from './styles.module.scss';

class Camera extends React.Component {
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.facingMode !== state.facingMode) {
            return { facingMode: nextProps.facingMode };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            flash: false,
            facingMode: this.props.facingMode
        };
        this.video = React.createRef();
        this.canvas = React.createRef();
        this.camara = React.createRef();
        this.requestCamera();
    }

    componentDidMount() {
        window.addEventListener('touchstart', event => {
            if (event.touches.length > 1) {
                event.preventDefault();
                return false;
            }
        }, { passive: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.facingMode !== prevState.facingMode) {
            this.stopStreaming();
            this.requestCamera();
        }
        if (this.props.captureImage === true) {
            this.props.onClick(this.captureImage());
        }
    }

    componentWillUnmount() {
        this.stopStreaming();
    }

    stopStreaming = () => {
        clearTimeout(this.drawTimeout);

        if (this.stream) {
            this.stream.getVideoTracks().forEach(track => track.stop());
        }

        if (this.video.current) {
            this.video.current.pause();
            this.video.current.removeEventListener('play', this.drawCanvas);
        }
    };

    requestCamera = async () => {
        try {
            const constraints = {
                audio: false,
                video: {
                    width: { ideal: 4096 },
                    height: { ideal: 2160 },
                    facingMode: this.state.facingMode
                }
            };

            this.stream = await navigator.mediaDevices.getUserMedia(constraints);
            this.video.current && this.playStream();
        } catch (e) {
            if (/^NotAllowedError/.test(e)) {
                this.props.history.push('/' + this.props.locale + routes.CAMERA_BLOCKED);
            } else {
                // eslint-disable-next-line
                console.error(e);
            }
        }
    };

    playStream = async () => {
        if ('srcObject' in this.video.current) {
            this.video.current.srcObject = this.stream;
        } else {
            this.video.current.src = window.URL.createObjectURL(this.stream);
        }

        this.video.current.addEventListener('loadedmetadata', this.triggerPlay);
        this.video.current.addEventListener('play', this.drawCanvas);
    };

    triggerPlay = () => {
        if (this.video.current) {
            this.video.current.play();
            this.video.current.removeEventListener('loadedmetadata', this.triggerPlay);
        }
    };

    drawCanvas = () => {
        const canvas = this.canvas.current;
        const ctx = canvas.getContext('2d');

        if (this.state.facingMode === 'user') {
            this.mirrorImage(ctx);
        }

        this.drawImage(ctx);

        ctx.fillStyle = 'rgba(0, 0, 0, 0.65)';
        ctx.fillRect(0, 0, this.props.innerWidth, this.props.innerHeight);
        ctx.save();

        const path = new window.Path2D();

        const { width: ellipseWidth, height: ellipseHeight } = getEllipseSize(this.props.innerWidth);

        path.ellipse(
            this.props.innerWidth / 2,
            (this.props.innerHeight - 120) * 0.5,
            ellipseWidth,
            ellipseHeight,
            Math.PI,
            0,
            2 * Math.PI
        );

        ctx.clip(path);

        this.drawImage(ctx);

        ctx.clip(path);
        ctx.restore();

        if (this.state.facingMode === 'user') {
            this.mirrorImage(ctx);
        }

        this.drawTimeout = setTimeout(this.drawCanvas, 1000 / 30);
    };

    mirrorImage = (ctx, width) => {
        ctx.translate(width || this.props.innerWidth, 0);
        ctx.scale(-1, 1);
    };

    drawImage = ctx => {
        const { width, height, offsetX, offsetY } = getScaledVideoRatio(this.video.current, this.props.innerWidth, this.props.innerHeight);
        return ctx.drawImage(
            this.video.current,
            offsetX,
            offsetY,
            width,
            height
        );
    };

    setStatePromise = (state, delay = 0) => new Promise(resolve => {
        this.setState(state, () => setTimeout(resolve, delay));
    });

    resizeCanvas = ({ width, height }) => {
        clearTimeout(this.drawTimeout);
        const canvas = this.canvas.current;
        canvas.width = width * window.devicePixelRatio;
        canvas.height = height * window.devicePixelRatio;
        const ctx = canvas.getContext('2d');

        this.mirrorImage(ctx, canvas.width);

        const { width: imageWidth, height: imageHeight } = getScaledVideoRatio(this.video.current, this.props.innerWidth, this.props.innerHeight);

        ctx.drawImage(
            this.video.current,
            (canvas.width - imageWidth) / 2,
            (canvas.height - imageHeight) / 2,
            imageWidth,
            imageHeight
        );

        const image = this.canvas.current.toDataURL('image/png');

        canvas.width = this.props.innerWidth;
        canvas.height = this.props.innerHeight - 120;

        this.drawCanvas();

        return image;
    };

    captureImage = async () => {
        const { width, height } = getEllipseSize(this.props.innerWidth);
        if (this.props.useFlash) {
            await this.setStatePromise({ flash: true }, 500);
        }

        const image = this.resizeCanvas({ width, height });
        const mouthSize = Math.min(200, this.props.innerWidth / 3);
        const mouth = this.resizeCanvas({ width: mouthSize, height: mouthSize });

        if (this.props.useFlash) {
            await this.setStatePromise({ flash: false });
        }
        return Promise.resolve({ image, mouth });
    };

    render() {

        return (
            <div className={styles.video_wrapper} ref={this.camara}>
                <video
                    ref={this.video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    className={styles.video}
                />
                <canvas
                    ref={this.canvas}
                    width={this.props.innerWidth}
                    height={this.props.innerHeight - 120}
                    className={styles.canvas}
                />
                {this.state.flash && (
                    <div className={styles.flash}/>
                )}
            </div>
        );
    }
}

Camera.propTypes = {
    innerWidth: PropTypes.number,
    innerHeight: PropTypes.number,
    useFlash: PropTypes.bool,
    facingMode: PropTypes.string,
    history: PropTypes.object,
    captureImage: PropTypes.bool,
    locale: PropTypes.string,
    onClick: PropTypes.func
};

export default withRouter(Camera);
