import * as actions from '../../../constants/actionTypes';

const initialState = {
    result: {}
};

const setQuizResult = (state, action) => {
    return {
        ...state,
        result: action.data
    };
};

const unsetQuizResult = state => {
    return {
        ...state,
        result: {}
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_QUIZ_RESULT:
            return setQuizResult(state, action);
        case actions.UNSET_QUIZ_RESULT:
            return unsetQuizResult(state);
        default:
            return state;
    }
};

export default reducer;