import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';
import Tile from '../Tile';
import { SCROLL_DIRECTIONS } from '../../constants/swiperOptions';
import ScrollContainer from './ScrollContainer';
import ProgressBar from './ProgressBar';
import i18n from 'i18next';
import swipeContent from '../../mock/swipeContent';
import LoaderScreen from '../LoadingScreen';

class Swipe extends React.Component {
    scrollContainer = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: this.props.location.state ? this.props.location.state.index : 0,
            slides: 0,
            quizLoader: { show: false, progress: 0, message: '' },
            isFormOpen: false,
            ...this.getBaseJourney()
        };
    }

    getBaseJourney = () => ({
        baseJourney: swipeContent.find(item => item.local === i18n.language).screens
    });

    setSlides = number => {
        this.setState({ slides: number });
    };

    setCurrentSlide = index => {
        this.setState({ currentSlide: index });
    };

    goToNextSlide = () => {
        const newIndex = this.state.currentSlide + 1 <= this.state.slides - 1 ? this.state.currentSlide + 1 : this.state.currentSlide;
        this.setState({ currentSlide: newIndex });
    };

    scrollTo = offset => {
        let currentOffset;
        currentOffset = offset === 'current' ? this.scrollContainer.current.slideSizes[this.state.currentSlide].height / 3 - 50 : offset;

        const DEFAULT_OFFSET = 200;

        this.scrollContainer.current.scrollTo(
            currentOffset || DEFAULT_OFFSET
        );
    };

    updateSlideHeight = () => {
        this.scrollContainer.current && this.scrollContainer.current.updateSlideHeights();
    };

    setQuizLoader = loader => {
        const tempLoaderData = {
            show: (typeof loader.show !== 'undefined')
                ? loader.show
                : this.state.quizLoader.show,
            progress: (typeof loader.progress !== 'undefined')
                ? loader.progress
                : this.state.quizLoader.progress,
            message: (typeof loader.message !== 'undefined')
                ? loader.message
                : this.state.quizLoader.message
        };

        this.setState({
            quizLoader: { ...tempLoaderData }
        });
    };

    toggleForm = () => {
        this.setState({ isFormOpen: !this.state.isFormOpen });
    };

    render() {
        return (
            <section className={styles.main_screen}>
                <ProgressBar
                    length={this.state.baseJourney.length}
                    active={this.state.currentSlide}
                />
                <div className={!this.state.quizLoader.show ? styles.swiper_content : styles.hide_swiper_content}>
                    <ScrollContainer
                        ref={this.scrollContainer}
                        scrollDirection={SCROLL_DIRECTIONS.ONLY_DOWN}
                        disabled={this.state.baseJourney[this.state.currentSlide].disableScroll}
                        slides={this.state.slides}
                        setSlides={this.setSlides}
                        currentSlide={this.state.currentSlide}
                        setCurrentSlide={this.setCurrentSlide}
                        nextSlide={this.goToNextSlide}
                        isFormOpen={this.state.isFormOpen}
                    >
                        {this.state.baseJourney.map((slide, i) => (
                            <Tile key={`tile-${i}`} disableScroll={slide.disableScroll}>
                                <slide.Content
                                    onNextStep={this.goToNextSlide}
                                    onSubStep={this.scrollTo}
                                    onAccordionClick={this.updateSlideHeight}
                                    setQuizLoader={this.setQuizLoader}
                                    isFormOpen={this.state.isFormOpen}
                                    toggleForm={this.toggleForm}
                                />
                            </Tile>
                        ))}
                    </ScrollContainer>
                </div>
                {this.state.quizLoader.show &&
                <LoaderScreen
                    progress={this.state.quizLoader.progress}
                    loadingMessage={this.state.quizLoader.message}
                />}
            </section>
        );
    }
}

Swipe.propTypes = {
    location: PropTypes.object
};

export default withRouter(Swipe);
