import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const PhotoPreview = props => (
    <div className={styles.image_wrapper}>
        <div className={styles.image}>
            <img src={props.image} />
        </div>
    </div>
);

PhotoPreview.propTypes = {
    image: PropTypes.string
};

export default PhotoPreview;