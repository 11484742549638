import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

class Dot extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            inited: false
        };
    }

    componentDidMount() {
        this.mounted = true;

        setTimeout(() => {
            if (this.mounted) {
                this.setState({ inited: true });
            }
        }, this.props.delay);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <div className={[styles.dot, this.state.inited ? styles.init_anim : ''].join(' ')}>
                <div className={[styles.inner_dot, this.props.active ? styles.active_dot : ''].join(' ') } />
            </div>
        );
    }
}

Dot.propTypes = {
    active: PropTypes.bool,
    delay: PropTypes.number
};

export default Dot;