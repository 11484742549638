const getAnswer = (answerId, answers) => {
    const answerExists = (answers)
        ? answers.some(item => (item.answer === answerId))
        : false;
    return answerExists;
};

const questionHasAnswer = (questionId, answers) => {
    const hasAnswer = (answers)
        ? answers.some(item => (item.question === questionId))
        : false;
    return hasAnswer;
};

const changeAnswers = (questionId, answer, answers) => {
    const tempAnswers = [...answers];
    const questionExsists = questionHasAnswer(questionId, answers);
    const answerExists = getAnswer(answer.id, answers);

    if (questionExsists) {
        if (answerExists) {
            tempAnswers.splice(tempAnswers.findIndex(item => item.question === questionId && item.answer === answer.id));
        } else {
            tempAnswers.forEach((item, index) => {
                if (item.question === questionId) {
                    tempAnswers[index].answer = answer.id;
                    tempAnswers[index].value = answer.value;
                }
            });
        }
    } else {
        tempAnswers.push({ question: questionId, answer: answer.id, value: answer.value });
    }

    return tempAnswers;
};

const isAnswerDisabled = (questions, questionId, answers) => {
    const question = questions.find(item => item.id === questionId);

    if (question && question.relatedAnswers && question.relatedAnswers.length > 0) {
        return !answers.some(answer => question.relatedAnswers.includes(answer.answer));
    }
    return false;
};

const resetDisabledAnswers = (questions, answers) => {
    let preparedAnswers = [];

    const disabledAnswers = remapQuestions(questions, answers)
        .reduce((result, next) => ([...result, ...next.answers]), [])
        .filter(answer => answer.disabled === true);

    answers.forEach(answer => {
        const answerIsDisabled = disabledAnswers.find(item => item.id === answer.answer);
        !answerIsDisabled && preparedAnswers.push({ ...answer });
    });

    // If the array of answers has gone through a change, reset answers again
    if (JSON.stringify(answers) !== JSON.stringify(preparedAnswers)) {
        preparedAnswers = resetDisabledAnswers(questions, preparedAnswers);
    }

    return preparedAnswers;
};

const handleDisabled = (questions, questionId, answers, answerId) => {
    return (
        isAnswerDisabled(questions, questionId, answers)
        || questionHasAnswer(questionId, answers)
            ? !getAnswer(answerId, answers)
            : false
    );
};

const remapQuestions = (questions, answers) => {
    const questionsTemp = questions.map(question => ({
        ...question,
        answers: question.answers.map(answer => ({
            ...answer,
            active: getAnswer(answer.id, answers),
            disabled: handleDisabled(questions, question.id, answers, answer.id)
        }))
    }));

    return questionsTemp;
};

const generateResult = answers => {
    const result = (answers && answers.length > 0)
        ? answers.map(item => item.value).join('-')
        : null;

    return result;
};

const getResultType = (quizResults, value) => {
    const result = quizResults.find(item => item.answersCombinations.includes(value));
    return result ? result.type : null;
};

export {
    getAnswer,
    changeAnswers,
    remapQuestions,
    resetDisabledAnswers,
    generateResult,
    getResultType
};