import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

const TextContainer = props => {

    const getParagraphs = paragraphs => paragraphs.map((item, index) => (
        <div key={index}>
            <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: item && item.title }}
            />
            <p
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: item && item.text }}
            />
        </div>
    ));

    return (
        <div className={styles.text_container}>
            {props.content && getParagraphs(props.content)}
        </div>
    );
};

TextContainer.propTypes = {
    content: PropTypes.array.isRequired
};

export default TextContainer;

