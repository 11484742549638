import { axiosInstance } from '../../../axios';
import * as api from '../../../constants/api';
import * as actionsTypes from '../../actionTypes';

const uploadImage = (image, mouth) => dispatch => {
    dispatch(actionsTypes.loadingStart());
    return new Promise((resolve, reject) => {
        axiosInstance.post(api.API_IMAGE, image)
            .then(response => {
                dispatch(actionsTypes.loadingSuccess());
                dispatch(actionsTypes.uploadImageSuccess(mouth));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(actionsTypes.loadingFail(error));
                reject(error.response ? error.response.data : error.message);
            });
    });
};

const uploadSampleData = (route, data, service) => dispatch => {
    dispatch(actionsTypes.loadingStart());
    return new Promise((resolve, reject) => {
        axiosInstance.post(route, data)
            .then(response => {
                dispatch(actionsTypes.loadingSuccess());
                dispatch(actionsTypes.serviceSuccess(service));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(actionsTypes.loadingFail(error));
                reject(error.response ? error.response.data : error.message);
            });
    });
};

const uploadCouponData = (route, data, service) => dispatch => {
    dispatch(actionsTypes.loadingStart());
    return new Promise((resolve, reject) => {
        axiosInstance.post(route, data)
            .then(response => {
                dispatch(actionsTypes.loadingSuccess());
                dispatch(actionsTypes.serviceSuccess(service));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(actionsTypes.loadingFail(error));
                reject(error.response ? error.response.data : error.message);
            });
    });
};

const sampleAvailability = () => dispatch => {
    dispatch(actionsTypes.loadingStart());
    return new Promise((resolve, reject) => {
        axiosInstance.get(api.API_SAMPLE_AVAILABILITY)
            .then(response => {
                dispatch(actionsTypes.loadingSuccess());
                dispatch(actionsTypes.sampleAvailabilitySuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(actionsTypes.loadingFail(error));
                reject(error.response ? error.response.data : error.message);
            });
    });
};

const takeTest = data => dispatch => {
    return new Promise(resolve => {
        dispatch(actionsTypes.takeTestSuccess(data));
        resolve(data);
    });
};

export {
    uploadImage,
    sampleAvailability,
    uploadSampleData,
    takeTest,
    uploadCouponData
};