import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import arrow from '../../../assets/svg/arrow.svg';

const Arrow = props => {
    return (
        <div className={styles.arrow}>
            <img src={arrow} alt="" className={props.active ? styles.up : styles.down}/>
        </div>
    );
};

Arrow.propTypes = {
    active: PropTypes.bool
};

export default Arrow;