export const Abbreviations = [
    { id: 1, name: 'AL' },
    { id: 2, name: 'AK' },
    { id: 3, name: 'AZ' },
    { id: 4, name: 'AR' },
    { id: 5, name: 'CA' },
    { id: 6, name: 'CO' },
    { id: 7, name: 'CT' },
    { id: 8, name: 'DE' },
    { id: 9, name: 'FL' },
    { id: 10, name:	'GA' },
    { id: 11, name:	'HI' },
    { id: 12, name:	'ID' },
    { id: 13, name:	'IL' },
    { id: 14, name:	'IN' },
    { id: 15, name:	'IA' },
    { id: 16, name:	'KS' },
    { id: 17, name:	'KY' },
    { id: 18, name:	'LA' },
    { id: 19, name:	'ME' },
    { id: 20, name:	'MD' },
    { id: 21, name:	'MA' },
    { id: 22, name:	'MI' },
    { id: 23, name:	'MN' },
    { id: 24, name:	'MS' },
    { id: 25, name:	'MO' },
    { id: 26, name:	'MT' },
    { id: 27, name:	'NE' },
    { id: 28, name:	'NV' },
    { id: 29, name:	'NH' },
    { id: 30, name:	'NJ' },
    { id: 31, name:	'NM' },
    { id: 32, name:	'NY' },
    { id: 33, name:	'NC' },
    { id: 34, name:	'ND' },
    { id: 35, name:	'OH' },
    { id: 36, name:	'OK' },
    { id: 37, name:	'OR' },
    { id: 38, name:	'PA' },
    { id: 39, name:	'RI' },
    { id: 40, name:	'SC' },
    { id: 41, name:	'SD' },
    { id: 42, name:	'TN' },
    { id: 43, name:	'TX' },
    { id: 44, name:	'UT' },
    { id: 45, name:	'VT' },
    { id: 46, name:	'VA' },
    { id: 47, name:	'WA' },
    { id: 48, name:	'WV' },
    { id: 49, name:	'WI' },
    { id: 50, name:	'WY' }
];