import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Input = props => {
    return (
        <div
            className={[
                styles.inputField,
                props.containerStyle
            ].join(' ')}
        >
            <label>{props.text}</label>
            <input
                type="text"
                autoComplete="off"
                name={props.name}
                value={props.value || ''}
                onChange={event => props.onChange(event.target)}
            />
            {props.error && <span className={styles.error} dangerouslySetInnerHTML={{ __html: props.error }} />}
        </div>
    );
};

Input.propTypes = {
    text: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    containerStyle: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.string
};

export default Input;