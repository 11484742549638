import * as videoFormats from '../constants/videoFormats';
import * as gtmEventTypes from '../constants/gtmEventTypes';

export const GTM_CATEGORY_ONLINE_CHECKUP = 'Online Check Up';

const doesSupportWebm = () => {
    if (typeof window !== 'undefined') {
        const video = document.createElement('video');
        return video.canPlayType(videoFormats.WEBM) === 'probably';
    }
    return false;
};

const trackCustomEvent = (category, action, label) => {
    if (window && window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({
            'event': gtmEventTypes.CUSTOM_EVENT,
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label
        });
    }
};

const trackPageView = ({ title, url }) => {
    if (window && window.dataLayer && window.dataLayer.push) {
        window.dataLayer.push({
            'event': gtmEventTypes.VIRTUAL_PAGE_VIEW,
            'virtualPageTitle': title,
            'virtualPageURL': url
        });
    }
};

const tryRequire = () => {
    try {
        const file = require('../config/config.json');
        return file;
    } catch (err) {
        return null;
    }
};

const getEnvironmentConfig = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    console.log('********** Environment: ' + environment + ' **********');
    const config = tryRequire();
    if (config) {
        if (!config[environment.trim()]) {
            console.log('Missing config.json.');
            return null;
        }
        console.log('Loaded data from config.json');
        return config[environment.trim()];
    }

    return null;
};

const getLocal = (match = window.location.pathname) => (match.match(/^\/?([a-z]{2}-[A-z]{2})/g) || [])[0];

const deepCloneSimpleObject = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export {
    doesSupportWebm,
    trackCustomEvent,
    trackPageView,
    getEnvironmentConfig,
    getLocal,
    deepCloneSimpleObject
};
