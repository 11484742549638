export const LOADING_START = 'LOADING_START';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const LOADING_FAIL = 'LOADING_FAIL';

export const SET_QUIZ_RESULT = 'SET_QUIZ_RESULT';
export const UNSET_QUIZ_RESULT = 'UNSET_QUIZ_RESULT';

export const SAMPLE_AVAILABILITY_SUCCESS = 'SAMPLE_AVAILABILITY_SUCCESS';

export const TAKE_TEST_SUCCESS = 'TAKE_TEST_SUCCESS';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';

export const SERVICE_SUCCESS = 'SERVICE_SUCCESS';

export const SET_TERMS_SUCCESS = 'SET_TERMS_SUCCESS';