import React from 'react';
import withBackgroundVideo from '../../hoc/withBackgroundVideo';
import Tile from '../../Tile';

import { useTranslation } from 'react-i18next';
import translations from '../../../constants/translations';

import styles from './styles.module.scss';
import sensodyneLogo from '../../../assets/svg/sensodyne-logo.svg';

const DesktopInfo = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.desktop_info}>
            <div className={styles.container}>
                <Tile>
                    <div className={styles.content}>
                        <div className={styles.content_images}>
                            <img src={t(translations.home.desktopLogo)}></img>
                            <img src={sensodyneLogo}></img>
                        </div>
                        <p>{t(translations.home.desktopInfo)}</p>
                    </div>
                </Tile>
            </div>
        </div>
    );
};

export default withBackgroundVideo(DesktopInfo, true, true);