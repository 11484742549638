import * as actions from '../../../constants/actionTypes';

const initialState = {
    error: null,
    isLoading: false,
    sampleAvailable: null,
    takeTest: false,
    startPageVisited: false,
    userImage: null,
    terms: false,
    userService: 'default'
};

const loadingStart = state => {
    return {
        ...state,
        isLoading: true,
        error: null
    };
};

const loadingFail = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error
    };
};

const loadingSuccess = state => {
    return {
        ...state,
        isLoading: false,
        error: null
    };
};

const uploadImageSuccess = (state, action) => {
    return {
        ...state,
        userImage: action.data
    };
};

const serviceSuccess = (state, action) => {
    return {
        ...state,
        userService: action.data
    };
};

const sampleAvailabilitySuccess = (state, action) => {
    return {
        ...state,
        sampleAvailable: !action.data.DailyLimitMet,
        startPageVisited: true
    };
};

const takeTestSuccess = (state, action) => {
    return {
        ...state,
        takeTest: action.data
    };
};

const setTermsSuccess = state => {
    return {
        ...state,
        terms: true
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.LOADING_START:
            return loadingStart(state);
        case actions.LOADING_SUCCESS:
            return loadingSuccess(state);
        case actions.LOADING_FAIL:
            return loadingFail(state, action);
        case actions.SAMPLE_AVAILABILITY_SUCCESS:
            return sampleAvailabilitySuccess(state, action);
        case actions.TAKE_TEST_SUCCESS:
            return takeTestSuccess(state, action);
        case actions.UPLOAD_IMAGE_SUCCESS:
            return uploadImageSuccess(state, action);
        case actions.SET_TERMS_SUCCESS:
            return setTermsSuccess(state);
        case actions.SERVICE_SUCCESS:
            return serviceSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;