import { useState, useEffect } from 'react';

const userAgents = /Android|BlackBerry|iPhone|iPad|iPod|iPadOS|Opera Mini|IEMobile|WPDesktop/i;

const isMobileCheck = () => Boolean(
    navigator.userAgent.match(userAgents)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
);

const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(isMobileCheck());

    useEffect(() => {
        const handler = () => setIsMobile(isMobileCheck());
        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return isMobile;
};

export default useDeviceType;